import React, {useEffect, useState} from "react";
import {validationData} from "../../../../components/UI/formValid";
import {myaxios} from "../../../../services/axios";
import {toastifySuccess} from "../../../../components/toastify/toastify";
import {NewAutoSelect} from "../../../../components/NewUi/NewAutoSelect";
import Input from "../../../../components/UI/Input";
import {useParams} from "react-router-dom";
import Loader from "../../../../components/Loader";
import {parseBackValidationErrors} from "../../../../helpers/parseBackValidationErrors";

const validationRules = {
    role_id: { required: true },
    first_name: { required: true, maxLength: 50 },
    last_name: { required: false, maxLength: 50 },
    email: { required: true, maxLength: 80, email: true },
    phone: { required: false, maxLength: 40 },
}

export const UserView = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        role_id: null,
        role: null,
        shop_id: null,
        shop: null,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        password_repeat: '',
        tch: true
    })

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`ajax/user/${id}?expand=Role,Shop`)
            if (status === 200) {
                setValues(data)
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [])

    const saveData = async () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }

        if ((values.password || values.password_repeat) && (values.password !== values.password_repeat)) {
            setErrors({
                password: 'Паролі не співпадають',
                password_repeat: 'Паролі не співпадають'
            })
            return
        }
        setErrors({})

        setLoading(true)

        let new_password = ""
        if (values.password) new_password = values.password
        try {
            const { status, data, request } = await myaxios.put(`ajax/user/${id}`, {
                ...values, password: null, new_password, name: null
            })
            if (status === 200 && data.id) {
                toastifySuccess('Користувач був оновлений')
                setValues({ ...values, tch: true })
            }
            if (request?.status === 422) {
                const res = JSON.parse(request.response)
                if (res.errors) setErrors(parseBackValidationErrors(res.errors))
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return (
        <div>
            {loading && <Loader />}
            <div className={'newList-btn-group'}><div>

                </div>
                <div>
                    {/*<a className={'butt-group-card-wrap btni ml10'}>Видалити</a>*/}
                    <button onClick={saveData} disabled={values.tch} className={'ml5 success btni'}>Зберегти</button>
                </div>
            </div>
            <div className={'sbform form-container'}>
                <div className="w50-p">
                    <NewAutoSelect
                        label={'Роль'}
                        searchBy={'name'}
                        entity={values.role}
                        link={'ajax/role?filter[name][like]='}
                        updateData={(role) => setValues({ ...values, role_id: role && role.id, role, tch: false })}
                        onEnterData={(role) => setValues({ ...values, role_id: role && role.id, role, tch: false })}
                        errorMessage={errors.role_id} valid={!errors.role_id} shouldValidate
                        clear
                    />
                </div>
                <div className="w50-p">
                    <NewAutoSelect
                        label={'Магазин'}
                        searchBy={'name'}
                        entity={values.shop}
                        link={'ajax/shop?filter[name][like]='}
                        updateData={(shop) => setValues({ ...values, shop_id: shop && shop.id, shop, tch: false })}
                        onEnterData={(shop) => setValues({ ...values, shop_id: shop && shop.id, shop, tch: false })}
                        errorMessage={errors.shop_id} valid={!errors.shop_id} shouldValidate
                        clear
                    />
                </div>
                <div className="w50-p">
                    <Input
                        label={'Ім\'я'}
                        value={values.first_name}
                        onChange={e => setValues({...values, first_name: e.target.value, tch: false })}
                        errorMessage={errors.first_name} valid={!errors.first_name} shouldValidate
                    />
                </div>
                <div className="w50-p">
                    <Input
                        label={'Прізвище'}
                        value={values.last_name}
                        onChange={e => setValues({...values, last_name: e.target.value, tch: false })}
                        errorMessage={errors.last_name} valid={!errors.last_name} shouldValidate
                    />
                </div>
                <div className="w50-p">
                    <Input
                        label={'Email'}
                        value={values.email}
                        onChange={e => setValues({...values, email: e.target.value, tch: false })}
                        errorMessage={errors.email} valid={!errors.email} shouldValidate
                        autocomplete={"new-email"}
                    />
                </div>
                <div className="w50-p">
                    <Input
                        label={'Телефон'}
                        value={values.phone}
                        onChange={e => setValues({...values, phone: e.target.value, tch: false })}
                        errorMessage={errors.phone} valid={!errors.phone} shouldValidate
                    />
                </div>
                <div className="w50-p">
                    <Input
                        label={'Пароль'}
                        value={values.password}
                        onChange={e => setValues({...values, password: e.target.value, tch: false })}
                        errorMessage={errors.password} valid={!errors.password} shouldValidate
                        type={'password'}
                        autocomplete={"new-password"}
                    />
                </div>
                <div className="w50-p">
                    <Input
                        label={'Повторіть пароль'}
                        value={values.password_repeat}
                        onChange={e => setValues({...values, password_repeat: e.target.value, tch: false })}
                        errorMessage={errors.password_repeat} valid={!errors.password_repeat} shouldValidate
                        type={'password'}
                    />
                </div>
            </div>
        </div>
    )
}