import React, {useContext, useEffect, useRef, useState} from "react";
// import {GlobalState} from "../Layout/Layout";
import {ModalLoader} from "./UI/Loader";
import GoogleDocsViewer from "react-google-docs-viewer";
import Bgdrop from "../Modal/Bgdrop";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/mode-css"
import "ace-builds/src-noconflict/mode-php"
import "ace-builds/src-noconflict/mode-sass"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/mode-jsx"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/mode-xml"
import "ace-builds/src-noconflict/theme-monokai"
import {toastifySuccess, toastifyWarning} from "./toastify/toastify";
import {myaxios} from "../services/axios";
import axios from "axios";
import Loader from "./Loader";

function ext(name) {
    let m = name.match(/\.([^.]+)$/)
    return m && m[1]
}

export const FilesDropDown = ({ entity_id, entity_type, updateFiles, updateFile }) => {
    const [files, setFiles] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    // const { state: { allowed_file_extensions } } = useContext(GlobalState)
    const allowed_file_extensions = 'accessOpenFormats'
    const inputRef = useRef(null)
    const labelRef= useRef(null)
    const allowedFileExtensions = allowed_file_extensions && allowed_file_extensions.split(',').map(item => item.trim())
    const [loading, setLoading] = useState(false)
    const [loadingProcess, setLoadingProcess] = useState(0)
    const [errorItems, setErrorItems] = useState([])
    const [isHover, setIsHover] = useState(false)
    const [mainLoader, setMainLoader] = useState(true)
    const [openFileId, setOpenFileId] = useState(null)
    const [htmlText, setHtmlText] = useState('')
    
    const getAllFiles = async () => {
        setMainLoader(true)
        try {
            const { status, data } = await myaxios.get(`/ajax/file?entity_type=${entity_type}&entity_id=${entity_id}`)
            if (status === 200) {
                setFiles(data.items)
            }
        } catch (e) {

        }
        setMainLoader(false)
    }

    const del = async (id) => {
        if(window.confirm()){
            setMainLoader(true)
            try {
                const { status, data } = await myaxios.delete(`/ajax/file/delete/${id}?entity_type=${entity_type}&entity_id=${entity_id}`)
                if(data.success) {
                    getAllFiles().then()
                    toastifyWarning('Файл видалено успешно')
                }
            } catch (e) {

            }

            setMainLoader(false)
        }
    }

    useEffect(() => {
        getAllFiles().then()
    }, [])

    /*useEffect(() => {
        if(openFileId && openFileId.type === 'html') {
            setLoading(true)
            fetch(`https://dev.sboxcrm.com/ajax/file/download/${openFileId.id}`)
                .then(response=> response.text())
                .then(text=> setHtmlText(text));
            setLoading(false)
        }
    }, [openFileId])*/

    const result = async (files, entity) => {
        setMainLoader(true)
        for (const asyncFn of files) {
            try {
                if(allowedFileExtensions.includes(ext(asyncFn.name).toLowerCase())) {
                    setErrorItems(prevState => [{name: asyncFn.name, error: 'запрещенный тип файла'}, ...prevState])
                } else {
                    const fData = new FormData();
                    fData.append('file', asyncFn, asyncFn.name);
                    const { status, data } = await myaxios.post(`/ajax/file/upload?entity_type=${entity_type}&entity_id=${entity_id}`, fData, {
                        onUploadProgress: ProgressEvent => {
                            setLoadingProcess((ProgressEvent.loaded / ProgressEvent.total) * 100)
                        }}
                    );

                    if(data.success) {
                        await getAllFiles()
                        toastifySuccess('Файл завантажено')
                    } else if (!data.status && data.errors && data.errors.file) {
                        if(data.errors.file[0]) {
                            data.errors.file.map(item => {
                                if(typeof item === 'string') {
                                    toastifyWarning(item)
                                    setErrorItems(prevState => [{name: asyncFn.name, error: item}, ...prevState])
                                }
                            })
                        }
                    }
                    setLoadingProcess(0)
                }
            } catch (e) {

            }
        }
        setMainLoader(false)
    }

    const filesHandler = async (ev) => {
        setIsHover(false)
        if(loading && !ev.target && !ev.target.files && ev.target.files.length < 1) {
            return false;
        }
        setErrorItems([])

        await result(ev.target.files)
    }

    const dropHandler = async (ev) => {
        setIsHover(false)
        if(loading) {
            return false;
        }

        ev.preventDefault()
        if(loading && !ev.dataTransfer && !ev.dataTransfer.items && ev.target.files.length < 0) {
            return false;
        }
        setErrorItems([])
        let arr = []
        for (let i = 0; i < ev.dataTransfer.items.length; i ++) {
            arr.push(ev.dataTransfer.items[i].getAsFile())
        }
        await result(arr)
        // uploadFiles(ev.dataTransfer.items[0].getAsFile())
    }

    function dragOverHandler(ev) {
        setIsHover(true)
        ev.preventDefault();
    }

    if(mainLoader) {
        return (
            <div style={{ position: 'relative' }}>
                <Loader />
            </div>
        )
    }

    const codeFormats = ['html','js','jsx','sass','scss','xml','css','json','ts','tsx','php']
    const videoFormats = ['ogv','oga','ogx','ogg','wav','mp4','ogv','webm','mp3','mov']
    const imagesFormats = ['jpg', 'jpeg', 'png', 'gif', 'jfif', 'bmp', 'webp']
    const docsFormats = ['docx', 'doc', 'xlsx', 'xls', 'pdf', 'txt', 'rtf', 'pptx', 'ppt']

    const accessOpenFormats = [
        ...videoFormats,
        ...docsFormats,
        ...imagesFormats,
        ...codeFormats
    ]

    const downloadFile = async (id, name) => {
        setMainLoader(true)
        try {
            const { status, data } =
                await myaxios.get(`ajax/file/download/${id}?entity_type=${entity_type}&entity_id=${entity_id}`, {
                    responseType: 'blob',
                })


            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
        setMainLoader(false)
    }

    // 0 0 10px rgb(0 0 0 / 20%)
    return (
        <div className={'filesDropDown tab-content'}>
            {/*{openFileId && <div style={{
                position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, right: 0, bottom: 0, zIndex: 6,
                display: "flex", alignItems: 'center', justifyContent: "center"
            }}>
                <div style={{ position: 'relative', zIndex: 51 }}>
                    {docsFormats.includes(openFileId.type) && <GoogleDocsViewer
                        width="1000px"
                        height="calc(100vh - 100px)"
                        fileUrl={`https://dev.sboxcrm.com/ajax/file/download/${openFileId.id}`}
                    />}
                    {imagesFormats.includes(openFileId.type) &&
                        <img style={{ maxWidth: '1000px' }} src={`https://dev.sboxcrm.com/ajax/file/download/${openFileId.id}`} />
                    }
                    {videoFormats.includes(openFileId.type) &&
                        <video
                            controls
                            style={{ maxWidth: '1000px' }}
                            src={`https://dev.sboxcrm.com/ajax/file/download/${openFileId.id}`}
                        />
                    }
                    {codeFormats.includes(openFileId.type) &&
                        <AceEditor
                            mode={openFileId.type}
                            theme="monokai"
                            value={htmlText}
                            width="1000px"
                            height="700px"
                        />
                    }
                </div>
                <Bgdrop close={() => setOpenFileId('')} />
            </div>}*/}

            <label
                style={{
                    boxShadow: isHover && '0 0 10px rgb(0 0 0 / 20%)',
                    background: isHover && 'rgb(229 229 229)'
                }}
                className={'drop_zone__container'}
                ref={labelRef}
                onDragOver={event => dragOverHandler(event)}
                onDragLeave={() => setIsHover(false)}
                onDrop={event => dropHandler(event)}
                htmlFor="drop_zone">
                <input style={{ display: 'none' }} ref={inputRef} id="drop_zone" type="file" onChange={e => filesHandler(e)} multiple />
                <div className={'filesDropDown__zone'}>
                    {loading ? `Процес завантаження ${loadingProcess.toFixed()}%` : 'Перетягніть сюди файли'} <br />
                    {errorMessage && <em className={'filesDropDown__error-message'}>разрешенные типы файлов {allowed_file_extensions}</em>}
                </div>
            </label>

            <div className={'contact-page sp-table'} style={{ display: (errorItems || []).length === 0 && 'none' }}>
                <table>
                    <tr className="fst-tr">
                        <td>Не загружен</td>
                        <td>Ошибка</td>
                        <td />
                    </tr>
                    {
                        (errorItems || []).map(item => (
                            <tr style={{ height: '50px' }} key={item.id} className={'pull-up'}>
                                <td>{item.name}.{item.type}</td>
                                <td style={{ color: '#c16a6a' }}>{item.error}</td>
                            </tr>
                        ))
                    }
                </table>
            </div>


            <div className={'contact-page sp-table'} style={{ display: (files || []).length === 0 && 'none' }}>
                <table>
                    <tr className="fst-tr">
                        <td>Назва файлу</td>
                        <td>Тип</td>
                        <td>Дата створення</td>
                        <td />
                    </tr>
                    {
                        (files || []).map(item => (
                            <tr key={item.id} className={'pull-up'} onDoubleClick={() => {
                                if((accessOpenFormats || []).includes(item.type)) setOpenFileId({ id: item.id + '', type: item.type })
                            }}>
                                <td>{item.file_name}</td>
                                <td>{item.type}</td>
                                <td>{item.created_at}</td>
                                <td>
                                    <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                                        <button
                                            style={{
                                                borderRadius: '4px', color: 'white', display: 'flex', height: '34px', width: '34px',
                                                alignItems: 'center', justifyContent: 'center', textDecoration: 'none', border: 'none',
                                                background: 'linear-gradient(45deg, #929292 0%,#656565 47%,#948a8a 100%)', marginRight: '3px',
                                                opacity: accessOpenFormats.includes(item.type) ? 1 : 0
                                            }}
                                            onClick={() => setOpenFileId({ id: item.id + '', type: item.type })}
                                            disabled={!accessOpenFormats.includes(item.type)}
                                        >
                                            <i className={'flaticon-eye'} style={{ fontSize: '18px' }} />
                                        </button>
                                        <a
                                            style={{
                                                color: 'white', borderRadius: '4px', textDecoration: 'none',
                                                width: '90px', textAlign: 'center', marginTop: 0, marginRight: '3px'
                                        }}
                                            onClick={() => downloadFile(item.id, item.file_name)}
                                            className="btn-m">Скачати</a>
                                        <a onClick={() => del(item.id)} className="is">
                                            <i className={'flaticon-delete-1'} />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </table>
            </div>
        </div>
    );
}