import {useEffect, useState} from "react";
import {myaxios} from "../../services/axios";
import './history-table.css'
import {formatDateTime} from "../cardViewComponents/formatDate";
import {NavLink} from "react-router-dom";
import Loader from "../Loader";
import MainEditor from "../UI/Editor";
import {toastifySuccess, toastifyWarning} from "../toastify/toastify";

const otherFields = [
    { value: 'linked_product', label: 'Додано продукт' },
    { value: 'repeat', label: 'Нова/повторна' },
    { value: 'destroy_product', label: 'Продукт видалено' },
]

export const History = ({ entity_type, entity_id, isOpen, enitiObject, showProduct }) => {
    const [items, setItems] = useState([])
    const [count, setCount] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('entity_comment')

    const getItems = async (incType = 'entity_comment') => {
        setType(incType)
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`ajax/history?expand=user&filter[entity_type][eq]=${entity_type}&filter[entity_id][eq]=${entity_id}&page=1&filter[type][eq]=${incType}`)
            if (status === 200) {
                setItems(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    const getCount = async (incType = 'entity_comment') => {
        try {
            const { status, data } = await myaxios.get(`ajax/history/count?filter[entity_type][eq]=${entity_type}&filter[entity_id][eq]=${entity_id}&filter[type][eq]=${incType}`)
            if (status === 200) {
                setPageCount(data.pageCount)
                setCount(data.totalCount)
            }
        } catch (e) {}
    }

    const fetchMoreData = async () => {
        if(+page < +pageCount) {
            setLoading(true)
            try {
                const newPage = page + 1;
                setPage(newPage)
                const { data } = await myaxios.get(`ajax/history?page=${newPage}&filter[entity_type][eq]=${entity_type}&filter[entity_id][eq]=${entity_id}&filter[type][eq]=${type}`)
                setItems(prevState => ([
                    ...prevState,
                    ...data.items
                ]))
            } catch (e) {}
            setLoading(false)
        }
    };

    const [comment, setComment] = useState('')
    const [showComment, setShowComment] = useState(false)

    const createComment = async () => {
        if (comment.trim().length === 0) {
            toastifyWarning('Значення не може бути пустим')
            return false
        }

        try {
            const { status, data } = await myaxios.post(`ajax/history`, {
                type: 'entity_comment',
                entity_type, entity_id, field_name: 'comment', new_value: comment
            })
            if (status === 201) {
                setItems([ { ...data, user: { name: '---' } }, ...items ])
                toastifySuccess('Коментар успішно створено')
                setComment('')
                setShowComment(false)
            }
        } catch (e) {}
    }

    useEffect(() => {
        if (isOpen) {
            getItems().then()
            getCount().then()
        }
    }, [isOpen])

    return (
        <>
            <div className={'tab-content sbform history-table'} style={{ minHeight: '60px' }}>
                {loading && <Loader />}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h2 style={{ padding: 0 }}>{count === 0 ? 'Тут поки що нічого не змінювалось' : 'Історія змін інформації та коментарі'}</h2>
                    <div className="comment-tabs">
                        <a onClick={() => {
                            setType('entity_comment')
                            getItems('entity_comment').then()
                            getCount('entity_comment').then()
                        }} className={type === 'entity_comment' && "comment-tab-active"}>Коментарі та коммунікація</a>
                        <a onClick={() => {
                            setType('entity_update_field')
                            getItems('entity_update_field').then()
                            getCount('entity_update_field').then()
                        }} className={type === 'entity_update_field' && "comment-tab-active"}>Історія змін</a>
                        {showProduct && <a onClick={() => {
                            setType('product_update_field')
                            getItems('product_update_field').then()
                            getCount('product_update_field').then()
                        }} className={type === 'product_update_field' && "comment-tab-active"}>Зміни в продуктах</a>}
                    </div>
                </div>
                {type === 'entity_comment' && showComment && <div><MainEditor
                    toolbar={'bold italic underline strikethrough | numlist bullist removeformat | pagebreak'}
                    menubar={'a'} editorStyles clearText
                    value={comment}
                    height={200}
                    valid={true}
                    required={false}
                    onChange={(text) => setComment(text)}
                    disabled={false}
                    plugins={null}
                />
                <button onClick={createComment} className={'success fl-l btni mt10'}>Створити коментар</button>
                </div>}
                {type === 'entity_comment' && !showComment && <button onClick={() => setShowComment(true)} className={'success fl-l btni mt10'}>Додати коментар</button>}
                {(type === 'entity_update_field' || type === 'product_update_field') && <table>
                    {count > 0 && <tr>
                        <th>Змінений параметр</th>
                        <th>Старе значення</th>
                        <th>Нове значення</th>
                        <th>Ким змінено</th>
                        <th>Час зміни</th>
                    </tr>}
                    {
                        (items || []).map(item => {
                            const field = [...enitiObject, ...otherFields].find(el => el.value === item.field_name)

                            return (
                                <tr>
                                    <td>{field?.label || item.field_name}</td>
                                    <td>{item.oldValue ? item.oldValue.name : item.old_value}</td>
                                    <td>{item.newValue ? item.newValue.name : item.new_value}</td>
                                    <td>{item.user?.name}</td>
                                    <td>{formatDateTime(item.created_at)}</td>
                                </tr>
                            )
                        })
                    }
                </table>}
                {page < pageCount && type !== 'entity_comment' && <button style={{ display: 'table', margin: '10px auto' }} className={'butt-group-card-wrap btni ml10'} onClick={fetchMoreData}>Завантажити ще</button>}
            </div>

            {type === 'entity_comment' && <div style={{ marginTop: '-10px' }}>
                {
                    (items || []).map(item => (
                        <div className={'history-table-item'}>
                            <i className="flaticon-notes" />
                            <div className="history-table-item__container">
                                <div className="history-table-item__header">
                                    <b>Коментар</b>
                                    <div>
                                        <a>{item.user?.name}</a>
                                        <em>{formatDateTime(item.created_at)}</em>
                                    </div>
                                </div>
                                <div className="history-table-item__body editBlc">
                                    <p dangerouslySetInnerHTML={{ __html: item.new_value }} />
                                </div>
                            </div>
                        </div>
                    ))
                }
                {page < pageCount && type === 'entity_comment' && <button style={{ display: 'table', margin: '10px auto' }} className={'butt-group-card-wrap btni ml10'} onClick={fetchMoreData}>Завантажити ще</button>}
            </div>}
        </>
    )
}