import React from 'react';
import {withRouter} from 'react-router-dom';
import {CSSTransition} from "react-transition-group";
import cancelIcon from '../accets/fonts/icons/cancel.svg'

class LeaveModal extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {nextLocation: null, openModal: false};
        this.onCancel = this.onCancel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        this.unblock = this.props.history.block((nextLocation) => {

            if(nextLocation.pathname === this.props.location.pathname) {
                return true
            }

            if (this.props.when) {
                this.setState({
                    openModal: true,
                    nextLocation: nextLocation
                });
            }
            return !this.props.when;
        });
    }

    componentWillUnmount() {
        this.unblock();
    }

    onCancel() {
        this.setState({nextLocation: null, openModal: false});
    }

    onConfirm() {
        this.navigateToNextLocation();
    }

    navigateToNextLocation() {
        this.unblock();
        this.props.history.push(this.state.nextLocation.pathname);
    }

    render() {
        return (
            <CSSTransition in={this.state.openModal} timeout={200} classNames="slide" unmountOnExit>
                <div className="leave-modal">
                    <div className="leave-modal-container">
                        <img className="leave-modal-image" src={cancelIcon} alt=""/>
                        <h3 className="leave-modal-title">{this.props.title || "Ви впевнені?"}</h3>
                        <p className="leave-modal-description">{this.props.description || "У вас є незбережені дані. Якщо ви залишите сторінку, дані не збережуться!"}</p>
                        <button className="leave-modal-confirm" onClick={this.onCancel}>Залишитися</button>
                        <button className="leave-modal-cancel" onClick={this.onConfirm}>Залишити</button>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}


export default withRouter(LeaveModal);