import './layout.css'
import logo from "../../accets/images/logo-oneweb-white.png"
import {useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {myaxios} from "../../services/axios";
import {localStorageGetter, localStorageSetter} from "../../components/localStorageHelper";

export const Layout = ({ children }) => {
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
    //const { user, notificationsCount, rules, setAccessEmails, integrations } = useContext(ContextProvider)
    const [sidebarWidth, setSidebarWidth] = useState(230)
    const [accessEmail, setAccessEmail] = useState([])
    const rules = {}

    const logout = () => {
        myaxios.get(`logout`)
    }

    /*const getSomeData = async () => {
        try {
            const { status, data } = await myaxios.get(`ajax/email/sender/access`)
            if (status === 200) {
                setAccessEmail(data.items)
                setAccessEmails(data.items)
            }
        } catch (e) {}
    }

    useEffect(() => {
        getSomeData().then()
    }, [])*/

    const finance_link = localStorage.getItem('finance_link') || 'payment'

    const navigation = [
        /*{ key: 'task', icon: 'flaticon-list-3', link: `/task/${localStorageGetter('task_nav') || 'kanban'}`, name: 'Задачі' },
        { key: 'email', icon: 'flaticon-email', link: `/email/${localStorageGetter('email') || ((accessEmail || [])[0]?.id) || 1}`, name: 'Email', hide: accessEmail.length === 0 },*/
        { key: 'contact', icon: 'flaticon-user', link: '/contact', name: 'Контакти' },
        /*{ key: 'account', icon: 'flaticon-users', link: '/account', name: 'Контрагенти' },
        { key: 'lead', icon: 'flaticon-user-ok', link: `/lead/${localStorageGetter('lead_nav') || 'kanban'}`, name: 'Ліди' },
        { key: 'opportunity', icon: 'flaticon-share', link: `/opportunity/${localStorageGetter('opportunity_nav') || 'kanban'}`, name: 'Угоди' },
        { key: 'order', icon: 'flaticon-cart', link: '/order', name: 'Замовлення' },
        { key: 'invoice', icon: 'flaticon-file-1', link: '/invoice', name: 'Рахунки' },
        { key: 'finance', icon: 'flaticon-coins', link: `/finance/${finance_link}`, name: 'Фінанси' },
        { key: 'document', icon: 'flaticon-interface-9', link: '/document', name: 'Документи' },
        { key: 'product', icon: 'flaticon-app', link: '/product', name: 'Продукти' },
        { key: 'stock', icon: 'flaticon-open-box', link: '/stock', name: 'Склад' },*/
        { key: 'settings', icon: 'flaticon-cogwheel-1', link: '/settings', name: 'Налаштування' },
    ]

    return (
        <div className={'new-layout nopagemargin'}>
            <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ marginTop: '5px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                        <img style={{ margin: '5px' }} width={135} src={logo} alt=""/>
                    </div>
                </div>
                {/*<div style={{ display: 'flex' }}>
                    {!!integrations.checkbox && <CheckboxShift />}
                    <a style={{ margin: '15px 15px 15px 8px', display: 'block', position: 'relative' }} className={+notificationsCount > 0 && 'icon-action'}>
                        <i onClick={() => setIsNotificationsOpen(true)} style={{ color: '#FFF', fontSize: '22px', cursor: 'pointer' }} className="flaticon-music-2" />
                    </a>
                </div>*/}
            </header>
            <div className={'new-layout__bottom'}>
                <aside style={{ width: sidebarWidth }}>
                    <div className={'new-layout__profile'}>
                        <div className={'new-layout__user-name'}>
                            {"user?.name"}
                        </div>
                    </div>
                    <div className={'new-layout__nav'}>
                        {
                            navigation.map(item => {
                                if (item.key === 'stock' && rules.consumption?.view === 'denied' && rules.order_return?.view === 'denied' && rules.transfer?.view === 'denied'
                                    && rules.shipment?.view === 'denied' && rules.purchase?.view === 'denied' && rules.replenish?.view === 'denied'
                                ) { return <></> }

                                if (item.key === 'finance' && rules.expense?.view === 'denied'
                                    && rules.fin_transfer?.view === 'denied' && rules.payment?.view === 'denied'
                                ) { return <></> }

                                if (rules[item.key]?.view !== 'denied') {
                                    return (
                                        <NavLink style={{ display: item.hide ? 'none' : '' }} to={item.link}
                                                 className={'new-layout__nav-item'}
                                        >
                                            <i className={item.icon} />
                                            <span>{item.name}</span>
                                        </NavLink>
                                    )
                                }
                            })
                        }
                    </div>
                </aside>
                <main>
                    {children}
                </main>
            </div>

            {/*{
                isNotificationsOpen && <NotificationsModal close={() => setIsNotificationsOpen(false)} />
            }*/}
        </div>
    )
}