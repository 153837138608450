import React, {useContext, useEffect} from 'react'
import Img from './error-boundry.png'
import {useHistory} from "react-router-dom";

const text = {
    '404': {
        heading: 'Сторінку не знайдено',
        desc: 'Схоже, цієї сторінки не існує'
    },
    'error': {
        heading: 'Щось пішло не так',
        desc: 'Спробуйте ще раз або напишіть, будь ласка, у технічну підтримку'
    }
}

const buttonStyles = { fontSize: '16px', padding: '8px 25px', textDecoration: 'none', margin: '0 auto', display: 'table', lineHeight: '26px' }

export const ErrorBoundaryComponent = ({ type }) => {
    const buttonSwitch = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const query = Object.fromEntries(urlSearchParams.entries());
        switch (query.entity) {
            default: return (
                <button style={{ margin: '0 auto', display: 'table' }} onClick={() => window.location.href = '/dashboard' } className={'mr5 success btni'}>{'На головну'}</button>
            )
        }
    }

    return (
        <div>
            <img className={'error-boundary__img'} style={{ maxWidth: '600px', display: 'block', margin: '0 auto' }} src={Img} alt=""/>
            <div>
                <h2 style={{ fontSize: '30px', fontWeight: 600, color: '#3e3e3e', textAlign: 'center', marginTop: '10px' }}>{text[type].heading}</h2>
                <p style={{ textAlign: "center", fontSize: '16px', fontWeight: 400, color: '#9c9c9c', margin: '10px auto', maxWidth: '400px', lineHeight: '20px' }}>
                    {text[type].desc}
                </p>
                {buttonSwitch()}
            </div>
        </div>
    )
}
