import React from 'react';
import Input from './UI/Input';
import Myselect from "./UI/Select";
import DateTime from "./UI/DateTime";
import Textarea from "./UI/Textarea";
import ModAutoSelect from "./UI/ModAutoSelect";
import formValid from "./UI/formValid";
import AutoSelect from "./UI/AutoSelect";
import MainEditor from "./UI/Editor";
import {toastifyWarning} from "./toastify/toastify";


export const isFormValid = (stateImport, extraFields = false, fc = "formControl") => {
    let state = stateImport;
    let isFormValid = true;
    Object.keys(state[fc]).map((key, index) => {
        //console.log("12132132", key, formValid(state[fc][key].value, state[fc][key].validation))
        state[fc][key].valid = formValid(state[fc][key].value, state[fc][key].validation);
        isFormValid = state[fc][key].valid && isFormValid;
    });
    if(extraFields) {
        let additionalFields = state.additionalFields;
        Object.keys(additionalFields).map((key, index) => {
            additionalFields[key].valid = formValid(additionalFields[key].value, additionalFields[key].validation);
            isFormValid = additionalFields[key].valid && isFormValid
        });
        state.additionalFields = additionalFields;
    }
    state.valid = isFormValid;
    if(!isFormValid) toastifyWarning('Помилка валідації')
    return state;
};

export const FieldGen = props => {
    let arr = [];
    const convert = () => {
        Object.keys(props.arr).map((key, index) => {
            arr.push({...props.arr[key], key: key})
        });
        return arr;
    }

    return <div className="sbform">
        {
            convert().map((item, index) =>
                <SwField
                    key={item.key}
                    type={item.type}
                    item={item}
                    setField={props.setField}
                    updateData={props.updateData}
                    unLink={props.unLink}
                />
            )
        }
    </div>
};

export const FieldGenZone = props => {
    let arr = [];
    const convert = () => {
        Object.keys(props.arr).map((key, index) => {
            arr.push({...props.arr[key], key: key})
        });
        return arr;
    }

    return <div className="sbform">
        {
            convert().map((item, index) =>(
                props.zone === item.zone ? <SwField
                    key={item.key}
                    type={item.type}
                    item={item}
                    setField={props.setField}
                    updateData={props.updateData}
                    setFieldAutoSelect={props.setFieldAutoSelect}
                    unLink={props.unLink}
                    disabled={props.disabled && (props.disabled[item.key] || false)}
                /> : null
            ))
        }
    </div>
}

const SwField = props => {
    const {item, type} = props;
    switch(type) {
        case "input": return (
            <Input
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                type={item.inputType || 'text'}
                label={item.label}
                value={item.value}
                className={item.cls}
                shouldValidate={true}
                valid={item.valid}
                tooltip={item.tooltip}
                onChange={e => props.setField(e.target.value, item.key)}
                disabled={props.disabled || item.disabled}
                errorMessage={item.em || `Поле не может быть пустым`}
            />
        );
        case "select": return (
            <Myselect
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                label={item.label}
                value={item.value}
                className={item.cls}
                shouldValidate={true}
                valid={item.valid}
                tooltip={item.tooltip}
                onChange={e => props.setField(e.target.value, item.key)}
                options={item.options}
                empty={item.empty}
                lab={item.lab}
                val={item.val}
                errorMessage={item.em || `Поле не может быть пустым`}
                disabled={props.disabled || item.disabled}
            />
        );
        case "date": return (
            <DateTime
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                tooltip={item.tooltip}
                label={item.label}
                className={item.cls}
                dateFormat="dd-MM-yyyy"
                value={item.value}
                valid={item.valid}
                errorMessage={item.em || `Поле не может быть пустым`}
                isClearable={true}
                handleChange={e => props.setField(e, item.key)}
                disabled={props.disabled || item.disabled}
            />
        );
        case "dateTime": return (
            <DateTime
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                tooltip={item.tooltip}
                label={item.label}
                className={item.cls}
                value={item.value}
                valid={item.valid}
                isClearable={item.isClearable}
                handleChange={e => props.setField(e, item.key)}
                showTimeSelect
                timeIntervals={item.timeIntervals}
                dateFormat="dd-MMMM-yyyy HH:mm"
                errorMessage={item.em || `Поле не может быть пустым`}
                timeFormat="HH:mm"
                disabled={props.disabled || item.disabled}
            />
        );
        case "textarea": return (
            <Textarea
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                label={item.label}
                value={item.value}
                className={item.cls}
                shouldValidate={true}
                valid={item.valid}
                tooltip={item.tooltip}
                onChange={e => props.setField(e.target.value, item.key)}
                cols={item.cols}
                rows={item.rows}
                errorMessage={item.em || `Поле не может быть пустым`}
                disabled={props.disabled || item.disabled}
            />
        );
        case "modAutoSelect": return (
            <ModAutoSelect
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                className={item.cls}
                label={item.label}
                link={item.link}
                minLength={item.minLength || 0}
                result={item.result}
                event={item.key}
                valid={item.valid}
                shouldValidate={true}
                addType={item.key}
                entity={item.entity}
                entityName={item.result}
                updateData={props.updateData}
                disabled={props.disabled || item.disabled}
                redirectLink={item.redirectLink}
                tooltip={item.tooltip}
                unLink={props.unLink}
                //ownerLink={this.state.formControl.owner.arr ? this.state.formControl.owner.arr.contact : null}
            />
        );
        case "AutoSelect": return (
            <AutoSelect
                value={item.value}
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                tooltip={item.tooltip}
                label={item.label}
                className={item.className}
                link={item.link}
                minLength={item.minLength || 0}
                result={item.result}
                event={item.key}
                addType={props.key}
                endParrams={item.endParrams}
                errorMessage={item.errorMessage}
                shouldValidate={true}
                valid={item.valid}
                setField={props.setFieldAutoSelect}
                updateData={props.updateData}
                disabled={props.disabled || item.disabled}
                placeholder={!item.entity && !props.setFieldAutoSelect ? "Выберите значение" : ''}
            />
        )
        case "checkbox": return (
            <label className="c12" style={{padding: "0px", margin: "10px 0px 0px"}}>
                <input onChange={e => props.setField(item.value == 1 ? 0 : 1, item.key)} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" checked={item.value == 1} />
                <span className="checkbox-custom" />
                <em style={{display: "inlineBlock", marginLeft: 4, position: "relative", top: 2, fontSize: 13}}>{item.label}</em>
            </label>
        );
        case "editor": return (
            <MainEditor
                required={item.validation ? (item.validation.required ? item.validation.required : false) : false}
                tooltip={item.tooltip}
                label={item.label}
                className={item.className}
                value={item.value}
                onChange={e => props.setField(e, item.key)}
                valid={item.valid}
                errorMessage={item.em || `Поле не может быть пустым`}
            />
        );
        default: return ""
    }
};

export function setField(fc, type, e){
    const thisFc = fc;
    thisFc[type].value = e;
    thisFc[type].valid = formValid(e, thisFc[type].validation);
    return thisFc;
}

export function updateData(fc, id, arr, type){
    const thisFc = fc;
    thisFc[type].entity = arr;
    thisFc[type].value = id;
    thisFc[type].valid = formValid(thisFc[type].value, thisFc[type].validation);
    return thisFc;
}

export function unLink(fc, type){
    const thisFc = fc;
    thisFc[type].entity = null;
    thisFc[type].value = '';
    thisFc[type].valid = formValid(thisFc[type].value, thisFc[type].validation);
    return thisFc;
}

export const getData = (formControl, res, expand) => {
    let arr = formControl
    Object.keys(formControl).map(key => {
        if(arr[key].type === "checkbox"){
            arr[key].value = res.data[key] == 1
        } else {
            arr[key].value = res.data[key] ? res.data[key] : ''
        }
        if(arr[key].from) arr[key].entity = res.data[arr[key].from]
    })
    return arr;
}

export const getPrepare = (formControl, res) => {
    Object.keys(formControl).map(key => {
        if(formControl[key].prepare){
            formControl[key].options = res[formControl[key].prepare.from];
            formControl[key].value = res[formControl[key].prepare.from][0][formControl[key].prepare.key];
        }
    })
    return formControl
}

export const postDt = (formControl) => {
    let arr = {};
    Object.keys(formControl).map((key, index) => {
        if(formControl[key].type === "dateTime" || formControl[key].type === "date"){
            arr[key] = formControl[key].value ? formControl[key].value.toLocaleString() : null;
        } else if(formControl[key].type === "checkbox" ) {
            arr[key] = formControl[key].value ? 1 : 0
        } else if(formControl[key].type === "modAutoSelect" || formControl[key].type === "emptySelect") {
            arr[key] = formControl[key].entity ? formControl[key].value : null;
        } else {
            if(formControl[key].post === "number") {
                arr[key] = Number(formControl[key].value);
            } else if(formControl[key].post === "string") {
                arr[key] = String(formControl[key].value);
            } else if(formControl[key].post === "false") {

            } else  {
                arr[key] = formControl[key].value;
            }
        }
    });
    return arr;
};

export function truncator(numToTruncate, intDecimalPlaces) {
    let numPower = Math.pow(10, intDecimalPlaces); // "numPowerConverter" might be better
    return ~~(numToTruncate * numPower)/numPower;
}

export const TabsRender = ({ arr, setState, state }) => {
    return (
        <div className="tabs-header">
            <ul>
                {
                    arr.map((item) => (
                        <li key={item.value}><a className={(state.layout === item.value) ? "active" : null} onClick={() => setState({ layout: item.value })}>{item.label}</a></li>
                    ))
                }
            </ul>
        </div>
    )
}

export const filesFunc = (type, index, arr, stateFiles) => {
    /*const files = stateFiles;
    if(type === "del" && arr.status === 200){
        delete files[index];
        Notif("delete", "Файл успешно удален")
    } else if(arr.status === "uploaded"){
        files.unshift({id: arr.id, name: arr.name, created_at: "Только что", type: arr.type});
        Notif("save", "Файл успешно загружен")
    } else if(arr.status === "error"){
        Notif("Error", arr.errors.file[0])
    }
    return files*/
};
