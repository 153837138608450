import React, {useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {NewFilterItem, operator} from "./components/NewFilterItem";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {NavLink} from "react-router-dom";
import Input from "../UI/Input";
import {convertFilter} from './components/convertFilter'
import SingleSearch from "../other/SingleSearchField";

export const ListFilter = ({
        filters,
        filterFields,
        saveFilter,
        LeftRender,
        RightRender,
        rightNavbarConfig,
        setIsColumnsOpen,
        singleSearch,updateData, entity, sort, sortActive, dropdownRender = () => {}, perPage, setPerPage, isFilterExists, deleteFilterItem
    }) => {
    const [isDropDownListOpen, setIsDropDownListOpen] = useState(false)
    const [isCreateFilter, setIsCreateFilter] = useState(false)
    const [filterSide, setFilterSide] = useState('left')
    const [singleSearchText, setSingleSearchText] = useState('')
    const [filterParams, setFilterParams] = useState({
        fieldConfig: {},
        logic: 'or',
        field: '',
        operator: '',
        value: '',
        entity: null,
        fieldType: ''
    })

    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsCreateFilter(false) })

    const changeFilterData = (el) => {
        const configData = filterFields.find(field => field.value === el.value)

        setFilterParams({
            fieldConfig: configData,
            logic: 'or',
            field: el.value,
            operator: operator[configData.fieldType][0].value,
            value: '',
            entity: null,
            fieldType: configData.fieldType
        })
    }

    return (
        <div>
            <div className={'newList-btn-group'}>
                <div className={'newList-btn-group-left'}>
                    <LeftRender />
                </div>
                <div className={'newList-btn-group-right'}>
                    {singleSearch && <SingleSearchField
                        { ...{ singleSearchText, singleSearch, setSingleSearchText, saveFilter } }
                    />}

                {isFilterExists && <div ref={dropdownRef} onClick={(e) => {
                    setFilterSide(e.clientX < 460 ? 'left' : 'right')
                    setIsCreateFilter(!isCreateFilter)
                }} className="create-filter">
                    <a className="btns">
                        <i className="flaticon-interface-3" />
                    </a>
                    <CSSTransition in={isCreateFilter && !filterParams.field} timeout={200} classNames="my-node" unmountOnExit>
                        <ul className={'create-filter-sublist scrollbar'}
                            style={{ left: filterSide === 'left' && 0, right: filterSide === 'right' && 0 }}
                        >
                            {
                                filterFields.map(el => (
                                    <li
                                        onClick={() => changeFilterData(el)}
                                        className={'create-filter-sublist-item'}>{el.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </CSSTransition>
                </div>}
                {rightNavbarConfig && <DropdownList
                    setIsDropDownListOpen={setIsDropDownListOpen}
                    isDropDownListOpen={isDropDownListOpen}
                    rightNavbarConfig={rightNavbarConfig}
                    setIsColumnsOpen={setIsColumnsOpen}
                    updateData={updateData}
                    entity={entity}
                    filters={filters}
                    sort={sort}
                    sortActive={sortActive}
                    saveFilter={saveFilter}
                    perPage={perPage} setPerPage={setPerPage}
                    >
                    {dropdownRender()}
                </DropdownList>}
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px' }}>
                {
                    filters.map((item) => (
                        <div
                            //data-tooltip={'CTRL + клик для изменения'}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                // if(e.ctrlKey) changeFilterData({ value: item.field })
                                deleteFilterItem(item.id).then()
                            }} key={item.id} className="new-filter-item">
                            {renderItem(item, filterFields)}
                            <a className={'new-filter-item-close'}>&#x2715;</a>
                        </div>
                    ))
                }
                {filterParams.field && <NewFilterItem
                    filterParams={filterParams}
                    filterFields={filterFields}
                    setFilterParams={data => setFilterParams(prevState => ({
                        ...prevState,
                        ...data
                    }))}
                    saveFilter={saveFilter}
                />}
            </div>
        </div>
    )
}

const SingleSearchField = ({ singleSearchText, singleSearch, setSingleSearchText, saveFilter }) => {
    return (
        <div className="single-search">
            <span style={{ cursor: 'default' }} className="single-search-label">Пошук по: {singleSearch.label}</span>
            <div className="single-search-container sbform">
                <Input
                    value={singleSearchText}
                    onChange={e => setSingleSearchText(e.target.value)}
                    onKeyUp={e => {
                        if(e.keyCode === 13 && singleSearchText.trim().length > 0) {
                            saveFilter([{ logic: 'or', field: singleSearch.value, fieldType: "text", operator: "like", value: singleSearchText }])
                            setSingleSearchText('')
                        }
                    }}
                />
            </div>
        </div>
    )
}

const DropdownList = ({ children, setIsDropDownListOpen, isDropDownListOpen, rightNavbarConfig, entity, filters,
                          setIsColumnsOpen, updateData, sort, sortActive, saveFilter, perPage, setPerPage }) => {
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsDropDownListOpen(false) })

    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <a onClick={() => setIsDropDownListOpen(!isDropDownListOpen)} className="fl-r btns"><i className="flaticon-more" /></a>
            <CSSTransition in={isDropDownListOpen} timeout={200} classNames="my-node" unmountOnExit>
                <div className="toogle-list" style={{ display: "block", top: '45px', right: 0 }}>
                    {children}
                    {rightNavbarConfig.export && <li>
                        <a onClick={() => {updateData("Export", {type: entity, filter: convertFilter(filters) } )}}><i className="flaticon-folder-2" /> <b>Експорт</b></a>
                    </li>}
                    {rightNavbarConfig['import'] && <li>
                            <NavLink to={rightNavbarConfig['import']}><i className="flaticon-folder-3" /> <b>Імпорт</b></NavLink>
                        </li>}
                    <SortList list={sort} sortActive={sortActive} saveFilter={saveFilter} />
                    {perPage && <PerPageList perPage={perPage} setPerPage={setPerPage} />}
                    {rightNavbarConfig.additionalFields && <li>
                        <NavLink to={rightNavbarConfig.additionalFields}><i className="flaticon-cogwheel-1" /> <b>Налаштування полів</b></NavLink>
                    </li>}
                    {rightNavbarConfig.columns && <li>
                        <a onClick={() => setIsColumnsOpen(true)}><i className="flaticon-interface-8" /> <b>Налаштування колонок</b></a>
                    </li>}
                    <li><a target={'_blank'} href="https://sboxcrm.com/docs/list"><i className="flaticon-questions-circular-button" /> <b>Робота зі списком</b></a></li>
                </div>
            </CSSTransition>
        </div>

    )
}

const PerPageList = ({ perPage = 20, setPerPage }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef2 = useRef(null)
    useOnClickOutside({ ref: dropdownRef2, handler: () => setIsOpen(false) })

    const perPageList = [20, 50, 100, 200, 500]

    return (
        <>
            <li ref={dropdownRef2} style={{ position: 'relative' }}>
                <a onClick={() => setIsOpen(!isOpen)}><i className="flaticon-list" /> <b>Показати: {perPage} елементів</b></a>
                <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
                    <div className="toogle-list scrollbar" style={{ display: "block", top: '0', right: '185px', maxHeight: '200px' }}>
                        {
                            perPageList.map(item => (
                                <li key={item} onClick={() => item !== +perPage && setPerPage(item)}>
                                    <a><b style={{ color: '#2b2b2b' }}>{item}</b></a>
                                </li>

                            ))
                        }
                    </div>
                </CSSTransition>
            </li>
        </>
    )
}

const SortList = ({ list, sortActive, saveFilter }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef2 = useRef(null)
    useOnClickOutside({ ref: dropdownRef2, handler: () => setIsOpen(false) })

    return (
        <>
        <li ref={dropdownRef2} style={{ position: 'relative' }}>
            <a onClick={() => setIsOpen(!isOpen)}><i className="flaticon-interface-9" /> <b>Сортування</b></a>
                <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
                    <div className="toogle-list scrollbar" style={{ display: "block", top: '0', right: '185px', maxHeight: '200px' }}>
                        {
                            list.map(item => {
                                const isActive = sortActive === item.value || sortActive === `-${item.value}`
                                return <>
                                    <li  onClick={() => isActive && sortActive[0] !== '-' ? saveFilter([], null, '', '-' + item.value) : saveFilter([], null, '', item.value)}
                                        key={item.value} className={`toogle-list-sort ${isActive && 'toogle-list-active'}`}>
                                        <a>
                                            {isActive && <i className={`flaticon-up-arrow-1 ${sortActive[0] === '-' ? 'toogle-list-sort-simple' : 'toogle-list-sort-rotate'}`} />} <b>{item.label}</b></a>
                                    </li>
                                </>
                            })
                        }

                    </div>
                </CSSTransition>
            </li>
        </>
    )
}


export const renderItem = (item, filterFields) => {
    if(!item) return <></>

    const itemConfig = filterFields.find((el) => el.value === item.field)
    if(!itemConfig) return <></>

    const OperatorObject = () => {
        if(!operator[itemConfig.fieldType]) return item.operator;
        return operator[itemConfig.fieldType].find(el => el.value === item.operator).label.toLowerCase()
    }

    const Desc = () => <>{itemConfig.label} ({item.logic}): <em style={{ fontStyle: 'normal' }}><OperatorObject /></em></>

    if(item.fieldType === "entity" || item.fieldType === "lookup" || item.fieldType === "tags" || item.fieldType === "products") {
        return <>
            <Desc /> {item.obj && item.obj[itemConfig.filterSearch]}
        </>
    } else if(item.fieldType === "select" && itemConfig.options && itemConfig.options[0] && item.value) {
        return <><Desc /> {itemConfig.options.find(el => String(el.id) === String(item.value)).label}</>
    } else {
        return <>
            <Desc /> {item.value}
        </>
    }
}