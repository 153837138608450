import React from 'react'
import { ErrorBoundaryComponent } from './ErrorBoundaryComponent'
import axios from 'axios'

class ErrorBoundary extends React.Component{
    state = {
        hasError: false
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        if(error) {
            this.setState({ hasError: true })
        } else {
            this.setState({ hasError: false })
        }
    }

    render() {
        if(this.state.hasError) {
            return (
                <div className="page errorComp">
                    <ErrorBoundaryComponent type={'error'} />
                </div>
            )
        } else return this.props.children;
    }
}

export default ErrorBoundary
