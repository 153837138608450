import React from 'react';

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}
const Myselect = props => {
    let options;
    if(Array.isArray(props.options)){
        options = props.options;
    } else {
        let list = [];
        for(let key in props.options){
            list.push({value: key, label: props.options[key], /*type: props.type[key]*/ });
            options = list;
    }}
    const vl = props.value || "";
    const val = props.val || "value";
    const lab = props.lab || "label";
    return <div className={props.className} style={props.style}>
        {(props.label) ? <label data-tooltip={props.tooltip}>{(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null}
    <select onFocus={props.onFocus} onBlur={props.onBlur} name={props.name} value={vl + ''} onChange={props.onChange} style={{...props.style, border: isInvalid(props) && "1px solid #c2263f"}} disabled={props.disabled}>
        {(props.empty) ? <option value={``} /> : null }
        {
            options ? options.map((i, index)=>
                i && <option key={index} data-sel={i.type || i[props.datatype]} value={i[val] + ''}>{i[lab]}</option>
            ) : null
        }
    </select>
        {
            (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заповніть поле"}</span> : null
        }
    </div>

};

export default Myselect;