import React, {useState} from "react";
import './DragList.css'

export const DDlist = ({ list, setList, save, isColor, isOrder, createElement, field = 'value', secondField, deleteFunction }) => {
    const [activeCol, setActiveCol] = useState(null)
    const [draggableItem, setDraggable] = useState(null)

    const finishDrag = () => {
        if(draggableItem === null) {
            return;
        }
        if(draggableItem !== activeCol) {
            const item = JSON.parse(JSON.stringify(list[draggableItem]))
            const newArray = list.filter((el, i) => i !== draggableItem)
            newArray.splice(+activeCol, 0, item)
            setList(newArray.map((el, i) => ({ ...el, order: i+1 })))
            // const newArray = list.filter(el => el.id !== item.id)
            // newArray.splice(+activeCol, 0, item)
            // setList(newArray.map((el, i) => ({ ...el, order: i+1 })))
        }
        setActiveCol(null)
        setDraggable(null)
    }

    const changeText = (type, index, value) => {
        const changedData = JSON.parse(JSON.stringify(list))
        changedData[index][type] = value
        setList(changedData)
    }

    const deleteElement = (index) => {
        if(window.confirm('Видалити елемент?')) setList(list.filter((el, i) => i !== index))
    }

    return (
        <div className={'drag-list'}>
            {
                list.map((item, index) => (
                    <div
                        className={'drag-list__parent'}
                        key={index}
                        onDragStart={() => setTimeout(() => setDraggable(index), 100)}
                        onDragEnd={() => finishDrag()}
                        onDragOver={e => e.preventDefault()}
                        onDragEnter={(e) => {
                            e.stopPropagation()
                            setActiveCol(index)
                        }}
                        draggable={isOrder}
                        style={{
                            marginTop: activeCol === index && index !== draggableItem ? '50px' : 0
                        }}
                    >
                        {isOrder && <i className={'drag-icon flaticon-apps'} />}
                        <div
                            className={'drag-list__item'}
                            style={{ display: 'flex', flexGrow: 1 }}>
                            {isColor && <input
                                type="color"
                                value={item.color || '#000'}
                                onChange={e => changeText('color', index, e.target.value)}
                            />}
                            <div style={{ flexGrow: 1, display: 'flex' }}>
                                <input
                                    value={item.name}
                                    type={'text'}
                                    onChange={e => changeText('name', index, e.target.value)}
                                />
                                {secondField && <input
                                    value={item[secondField.value]}
                                    type={secondField.type}
                                    onChange={e => changeText(secondField.value, index, e.target.value)}
                                />}
                            </div>
                            {item[field] && item[field].trim().length === 0 && <div>
                                <i
                                    style={{ fontSize: '22px' }}
                                    data-tooltip={'Поле не може бути пустим'}
                                    onClick={() => deleteElement(index)}
                                    className={'flaticon-information drag-list__item-delete'}
                                />
                            </div>}
                            {/*<div>
                                {
                                    list.length > 1 && +item.is_systemic !== 1 ?
                                    <i
                                        onClick={() => deleteElement(index)}
                                        className={'flaticon-delete-2 drag-list__item-delete'}
                                    /> :
                                    <i
                                        style={{ color: 'rgb(105 105 105)', fontSize: '20px' }}
                                        className={'flaticon-lock-1 drag-list__item-delete'}

                                    />
                                }
                            </div>*/}
                            {deleteFunction && <div>
                                <i
                                    onClick={() => deleteFunction(item.id, index)}
                                    className={'flaticon-delete-2 drag-list__item-delete'}
                                />
                            </div>}
                        </div>
                    </div>
                ))
            }
            <div className={'drag-list__buttons'}>
                <button onClick={createElement} className="rem drag-list__buttons-create">Додати елемент</button>
                {/*<button onClick={save} className="rem">Сохранить</button>*/}
            </div>
        </div>
    )
}