import React from "react";
import {NewList} from "../../../../components/NewList/NewList";
import {NavLink, useHistory} from "react-router-dom";

export const RoleList = () => {
    const history = useHistory()

    return (
        <NewList
            entity={'role'}
            requiredFields={'id,name,description'}
            additionalFieldsExists={false}
            isFilterExists={false}
            headingColumns={['Назва', 'Опис']}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('role')} className="open-modal-button btni">Додати роль</button>}
            contextNav={{ edit: false, task: false, print: false, delete: true }}
            filterFields={[]}
            onCreatedResult={'update-list'}
            doubleClickLink={'/settings/role'}
            isPagination={true}
            RenderColumn={(item) => (
                <>
                    <td onDoubleClick={() => history.push(`/settings/role/${item.id}`)}>
                        <NavLink to={`/settings/role/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td onDoubleClick={() => history.push(`/settings/role/${item.id}`)}>
                        <span>{item.description}</span>
                    </td>
                </>
            )}
        />
    )
}