import moment from "moment";

export const extraFieldsValidationConfig = (item) => {
    const validationRules = {
        required: item.is_required,
        reg: item.regex
    }

    switch (item.field_type) {
        case 'text': {
            validationRules['maxLength'] = 60;
            return validationRules;
        }
        case 'number': {
            validationRules['number'] = true;
            return validationRules;
        }
        case 'url': {
            validationRules['maxLength'] = 60;
            validationRules['url'] = true;
            return validationRules;
        }
        case 'textarea': {
            validationRules['maxLength'] = 255;
            return validationRules;
        }
        default: {
            return validationRules
        }
    }
}

export const transformExtraFields = (arr, valuesArr, key) => {
    const extra_fields = {}
    const values = {}

    arr.map((item, i) => {
        const field = valuesArr.find(el => el.id === item.id)

        if (item.field_type === 'date' && field && field[key] && field[key].value) {
            field[key].value = moment(new Date(+field[key].value)).format("YYYY-MM-DD")
        }

        extra_fields[`f_${item.id}`] = {
            ...item,
            type: item.field_type,
            lab: 'name', val: 'id', empty: true,
            zone: item.zone,
            className: item.width,
            validation: extraFieldsValidationConfig(item)
        }
        if (field) {
            values[`f_${field.id}`] = field[key].value
        } else {
            values[`f_${item.id}`] = null
        }
    })

    //console.log(obj)
    return {
        extra_fields,
        ...values
    }
}

export const transformExtraFieldsToSend = (obj, field_key, extra_fields) => {
    const result = [];

    for (const prop in obj) {
        if (prop.includes('f_')) {
            const extra_field_object = extra_fields[prop]
            const key = prop.substring(2);
            const field = obj.extra_fields_start.find(el => +el.id === +key)
            let value = obj[prop];
            if (extra_field_object.field_type === 'date') {
                value = (+(new Date(value))).toFixed()
            }
            if (value !== null) {
                console.log('field', field)
                if (field) {
                    result.push({ id: field[field_key].id, value, field_id: +key })
                } else {
                    result.push({ value, field_id: +key });
                }
            }
        }
    }

    console.log('result', result)
    return result
}