export const convertFilter = (filters) => {
    let link = ''
    filters.map(item => {
        if(item.operator === 'like' || item.operator === 'nlike'){
            link+= `&adv_filter[${item.field}][${item.logic}][${item.operator}]=${item.value}`
        }
        if(item.operator === 'eq' || item.operator === 'ne' || item.operator === 'gt' || item.operator === 'gte' || item.operator === 'lt' || item.operator === 'lte' || item.operator === '>' || item.operator === '<' || item.operator === 'between'){
            link+= `&adv_filter[${item.field}][${item.logic}][${item.operator}]=${item.value}`
        }
        if(item.operator === 'in' || item.operator === 'nin'){
            link+= `&adv_filter[${item.field}][${item.logic}][${item.operator}]=${item.value}`
        }
    })
    return link;
}