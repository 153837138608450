import {formatDate} from "./formatDate";
import {toastifyWarning} from "../toastify/toastify";

export const parseResponse = (config, response, prepare) => {
    return Object.keys(config).reduce((previousValue, currentValue) => {
        if(config[currentValue].prepare) {
            const prepareLink = config[currentValue].prepare
            previousValue[prepareLink] = prepare[prepareLink]
            previousValue[currentValue] = prepare[prepareLink][0][config[currentValue].val]
        }
        if(config[currentValue].type === 'entity') {
            previousValue[config[currentValue].entity_from] = response[config[currentValue].entity_from]
            previousValue[currentValue] = response[currentValue]
        } else if(config[currentValue].type === 'date') {
            previousValue[currentValue] = response[currentValue] ? formatDate(new Date(response[currentValue])) : ''
        } else  if(config[currentValue].type === 'number') {
            previousValue[currentValue] = response[currentValue] || null
        } else {
            previousValue[currentValue] = response[currentValue] || ''
        }
        return previousValue
    }, {  })
}

export const parseResponseError = (response, history, link) => {
    const errors = {}
    if(response?.status === 404) {
        if (history && link) {
            history.push(link)
            toastifyWarning('Запису не існує')
        }
        errors.notFound = true
    }

    if (response?.status === 403) {
        if (history && link) {
            history.push(link)
        }
    }

    if (response?.status === 422 && response?.data?.errors) {
        errors.validationErrors = response.data.errors
    }

    if(response && response.data && response.data.status) {
        if(response.data.status === 'validation_error' && Object.keys(response.data.errors).length > 0) {
            errors.validationErrors = Object.keys(response.data.errors).reduce((value, key) => {
                value[key] = response.data.errors[key] && response.data.errors[key][0]
                return value
            }, {})
        } else if(response.status === 404) {
            errors.notFound = true
        } else if(response.data.type === "update_conflict") {
            errors.saveConflict = true
        }
    }

    return errors
}