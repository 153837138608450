import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import {MultipleField} from "../../UI/MultipleField/MultipleField";
import {myaxios} from "../../../services/axios";
import {convertFilter} from "./convertFilter";
import {toastifyWarning} from "../../toastify/toastify";

const validationRules = {
    name: { required: true, maxLength: 255 },
}

export const ExportModal = ({ close, options, additionalFields, filters, entity }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState([])


    const closeModal = () => { setIsOpen(false); setTimeout(() => { close() }, 200) }

    useEffect(() => {
        setIsOpen(true)
    }, [])

    const exportData = async () => {
        if (value.length === 0) {
            toastifyWarning('Оберіть як мінімум одне поле')
            return
        }

        const allOptions = [
            ...options,
            ...(additionalFields || []).map(el => ({ ...el, value: `f_${el.id}` }))
        ]

        const fieldNames = value.map(el => (allOptions.find(item => String(item.value) === String(el))?.label)).join()

        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`ajax/${entity}/export?columns=${value.join()}&entity=${entity}&field_names=${fieldNames}${convertFilter(filters)}`, {
                responseType: 'blob',
            })

            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'text.xls'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal isOpen={isOpen} title={'Експорт даних'} loading={loading} saveIcon={false}
               parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <MultipleField
                    label={'Поля для експорту'}
                    value={value}
                    options={[
                        ...options,
                        ...(additionalFields || []).map(el => ({ ...el, value: `f_${el.id}` }))
                    ]}
                    onChange={(value) => setValue(value)}
                    valid={true}
                />
                <button onClick={exportData} className="success btni mt10">Експорт</button>
            </div>
        </Modal>
    )
}