import axios, { AxiosInstance } from "axios";
import {
  toastifyError,
  toastifyWarning,
} from "../components/toastify/toastify";
import "react-toastify/dist/ReactToastify.css";
import Ar from "@amcharts/amcharts4/lang/ar";

//const baseUrl = process.env.REACT_APP_BASE_API

const currentUrl = window.location.href;
const urlParts = currentUrl?.split('.');
const subdomain = urlParts[0]?.split('//')[1];

export const myaxios = axios.create({
  //baseURL: `http://localhost:4001/`,
  baseURL: `https://${subdomain}.oneweb.ua/`,
  //baseURL: `https://test4.oneweb.ua/`,
  headers: {
    Authorization: `${localStorage.getItem("accessToken")}`,
  },
});

myaxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.href = "/";
      } else if (
        error.response.status === 422 ||
        error.response.status === 400
      ) {
        if (error.response.data.errors && Array.isArray(error.response.data.errors)) {
          for (const item of error.response.data.errors) {
            toastifyError(`${item.message}`)
          }
        }
      } else if (error.response.status === 403) {
        toastifyWarning(error?.response?.data?.message);
      } else if (error.response.status === 404) {
        toastifyError(error.response.data.message)
      } else if (error.response.status === 500) {
        if (error?.response?.data) {
          if (error.response.data.error) {
            if (
              error.response.data.error.errors &&
              error.response.data.error.errors[0]
            ) {
              toastifyError(error.response.data.error.errors[0].message);
            }
          } else if (error.response.data.message) {
            toastifyError(error.response.data.message);
          } else {
            toastifyError("Something went wrong");
          }
        } else {
          toastifyError("Something went wrong");
        }
      }
    }
    return error;
  }
);
