import React, {useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {NavLink} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {ModalLoader} from "../UI/Loader";
import {myaxios} from "../../services/axios";

let guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export const NewAutoSelect = ({
    link, entity, className, label, searchBy = 'name', onChange = () => {}, minLength = 0, updateData, value, disabled, hidePerPage, refresh,
    redirect, clear, itemName, valid = true, errorMessage, onEnterData, messageEmptyItem, searchByAlternative, tooltip, placeholder
}) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [search, setSearch] = useState('')
    const [onSearch, setOnSearch] = useState(false)
    const [page, setPage] = useState(1)
    const [activeItem, setActiveItem] = useState('')
    const [metaData, setMetaData] = useState({
        totalCount: 0,
        pageCount: 0
    })
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setOpen(false) })
    const perPage = !hidePerPage ? '&per-page=10' : ''

    const searchData = async () => {
        // if(search.length < minLength) return;

        setLoading(true)
        try {
            const { data, status } = await myaxios.get(link + search + perPage)
            if(status === 200 || status === 201) {
                setItems(data.items)
                if(data._meta) {
                    setMetaData({
                        pageCount: data._meta.pageCount,
                        totalCount: data._meta.totalCount
                    })
                }
                setOpen(true)
            }
        } catch (e) { }
        setLoading(false)
    }

    const fetchMoreData = async () => {
        if(page < metaData.pageCount) {
            const newPage = page + 1;
            setPage(newPage)
            setTimeout(() => {
                myaxios.get(link + search + `${perPage}&page=${newPage}`, {
                }).then((response) => {
                    setItems(prevState => ([
                        ...prevState,
                        ...response.data.items
                    ]))
                })
            }, 500);
        }
    };

    const onKeyAction = event => {
        if(event.keyCode === 40 || event.keyCode === 38) {
            const foundIndex = items.findIndex(el => el.id === activeItem)

            if(activeItem) {
                if(event.keyCode === 40 && items.length - 1 > foundIndex){
                    setActiveItem(items[foundIndex + 1].id)
                } else if(event.keyCode === 38 && foundIndex !== 0){
                    setActiveItem(items[foundIndex - 1].id)
                }
            } else if(items[0]) {
                setActiveItem(items[0].id)
            }
            return;
        }

        if(event.keyCode === 13 && activeItem) {
            const foundIndex = items.findIndex(el => String(el.id) === String(activeItem))

            if(foundIndex === null || foundIndex === undefined) {
                return;
            }
            const clickedItems = document.getElementsByClassName('sbox-autoselect__dropdown-item')
            let event = new Event("click", {bubbles: true});
            if(entity && clear) {
                clickedItems[foundIndex + 1].dispatchEvent(event);
            } else {
                clickedItems[foundIndex].dispatchEvent(event);
            }
            setActiveItem('')
        }
    }

    useEffect(() => {
        if(onSearch) searchData().then()
    }, [search])

    const selectId = guid()
    const [positionDropDown, setPositionDropDown] = useState('bottom')
    const dropDownItems = useRef(null)
    const enterInputRef = useRef(null)

    const changePositionDropDown = () => {
        if(open && dropDownItems && dropDownItems.current && dropDownItems.current.getBoundingClientRect()) {
            if((dropDownItems.current.getBoundingClientRect().height + dropDownItems.current.getBoundingClientRect().top + 200) > window.innerHeight) {
                setPositionDropDown('top')
            } else {
                setPositionDropDown('bottom')
            }
        }
    }

    useEffect(() => {
        changePositionDropDown()
    }, [open])

    const returnItemResult = (item) => {
        if (!item) return 'Немає елементу'
        if(searchBy && item[searchBy]) return item[searchBy]
        if(searchByAlternative && item[searchByAlternative]) return item[searchByAlternative]
        if(messageEmptyItem) return messageEmptyItem
        return 'Пусто'
    }

    return (
        <div className={className} style={{ opacity: disabled ? 0.7 : 1 }}>
            <input className={'input-hidden'} ref={enterInputRef} type={'text'} value={'onSaveDataTargetEnter'} name={itemName} onKeyDown={e => onEnterData && e.keyCode === 13 && onEnterData(null, e)} />
            {label && <label data-tooltip={tooltip}>{label} {(tooltip) ? <div className="squerTooltipe"/> : null}</label>}
            <div className={`sbox-autoselect`} ref={dropdownRef}>
                <div className={'sbox-autoselect-container'} style={{ border: !valid && '1px solid #f4516c' }}>
                    <div style={{ height: '35px', overflow: 'hidden' }}>
                        {entity && !open && <>
                            {redirect ?
                                <NavLink className={'sbox-autoselect-text'} to={`/${redirect}/${entity && entity.id}`}>{entity[searchBy]}</NavLink> :
                                <div onClick={() => dropDownItems && dropDownItems.current && dropDownItems.current.focus()} className={'sbox-autoselect-text'}>{entity[searchBy]}</div>
                            }
                        </>}
                        <div>
                            <input
                                // value={value}
                                onKeyUp={e => {
                                    setOnSearch(true)
                                    setSearch(e.target.value);
                                    onKeyAction(e)
                                }}
                                onChange={onChange}
                                onFocus={() => {
                                    items.length < 1 || refresh ? searchData() : setOpen(true)
                                }}
                                ref={dropDownItems} disabled={disabled}
                                placeholder={placeholder}
                            />
                        </div>
                    </div>
                    {search.length >= minLength && <em
                        onClick={() => !open ? dropDownItems && dropDownItems.current && dropDownItems.current.focus() : setOpen(false)}
                        className={`AutoSelectParent__child ${!!open && 'AutoSelectParent__child-active'}`}>&#10094;</em>}
                </div>
                <CSSTransition in={open} timeout={100} classNames="my-node" unmountOnExit>
                    <div
                        id={selectId} className={`${positionDropDown === 'top' && 'sbox-autoselect__dropdown-top'} sbox-autoselect__dropdown scrollbar`}
                        style={{
                            // display: items.length === 0 && 'none'
                        }}>


                        {loading && <div className="sbox-autoselect__dropdown-item">Завантаження</div>}
                        {items.length === 0 && <div className="sbox-autoselect__dropdown-item">Немає результатів</div>}

                        {entity && clear && <div name={itemName} value={null}
                            onClick={(e) => { updateData && updateData(null, e, 'clear'); setOpen(false) }}
                                className={'sbox-autoselect__dropdown-item sbox-autoselect__dropdown-item-clear'}>Очистити значення</div>}
                        <InfiniteScroll
                            dataLength={items ? items.length : []}
                            next={fetchMoreData}
                            hasMore={true}
                            scrollableTarget={selectId}
                            loader={(+page < +metaData.pageCount && items.length >= 1 ) ? <ModalLoader /> : null}
                        >
                            {
                                items && items[0] && items.map(item => (
                                    <div
                                        style={{
                                            background: activeItem === item.id && 'linear-gradient(45deg, #80aeba 2%,#699499 46%,#669295 100%)',
                                            color: activeItem === item.id && '#fff'
                                        }}
                                        name={itemName}
                                        value={item.id}
                                        onClick={(e) => {
                                            // enterInputRef.current.focus()
                                            e.stopPropagation()
                                            updateData && updateData(item, e)
                                            if (search.length > 0) setItems([])
                                            setSearch('')
                                            setOpen(false)
                                            setOnSearch(false)
                                            dropDownItems.current.value = ''
                                        }}
                                        className="sbox-autoselect__dropdown-item"
                                    >
                                        {returnItemResult(item)}
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    </div>
                </CSSTransition>
            </div>
            {!valid && <span className="errorMsg">{(errorMessage) || "Заповніть поле"}</span>}
        </div>
    )
}