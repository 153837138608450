import "./index.css"
import {Layout} from "./containers/Layout/Layout";
import ErrorBoundary from "./components/other/ErrorBoundry/ErrorBoundry";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Route, Switch, useLocation} from "react-router-dom";
import {router} from "./router";
import {Login} from "./containers/Auth/Login";
import {ToastContainer} from "react-toastify";
import {useContext, useEffect} from "react";
import {ContextProvider} from "./Context";
import {myaxios} from "./services/axios";

function App() {
    const { setUser, setRules } = useContext(ContextProvider)
    const location = useLocation()

    const getUserProfileState = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/user/state`)
            if (status === 200) {
                setUser(data.user)
                setRules(data.role.permissions)
            }
        } catch (e) {}
    }

    const token = localStorage.getItem("accessToken")
    useEffect(() => {
        if (localStorage.getItem("accessToken")) getUserProfileState().then();
    }, [token]);

    if (!localStorage.getItem("accessToken")) {
        return (
            <>
                <Login />
                <ToastContainer />
            </>
        );
    }

    return (
        <div>
            <Layout>
                <ErrorBoundary>
                    <TransitionGroup className={"transition-group-screen"}>
                        <CSSTransition
                            key={location}
                            timeout={{ enter: 500, exit: 0 }}
                            classNames={"slide"}
                        >
                            <Switch>
                                {router.map(({ path, Component, exact }) => (
                                    <Route
                                        key={path}
                                        exact={exact}
                                        path={path}
                                        render={(props) => <Component {...props} />}
                                        component={Component}
                                    />
                                ))}
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Layout>
            <ToastContainer />
        </div>
    );
}

export default App;
