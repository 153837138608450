import {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Loader, {ModalLoader} from "../../components/Loader";
import {
    deleteContactService,
    getSingleContactService,
    updateContactService
} from "../../services/contact/contactService";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import {contactConfig} from "./contactConfig";
import LeaveModal from "../../components/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import {DropDownButton} from "../../components/cardViewComponents/DropDownButton";
import {toastifyError, toastifySuccess, toastifyWarning} from "../../components/toastify/toastify";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import UploadAvatar from "../../components/other/UploadAvatar";
import {TabsRender} from "../../components/cardViewComponents/TabsRender";
import {CSSTransition} from "react-transition-group";
import {InfoTab} from "./components/InfoTab";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {validationData} from "../../components/UI/formValid";
import {myaxios} from "../../services/axios";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import {Tasks} from "../../components/Task";
import {History} from "../../components/history/History";
import {entity} from "../../components/ListComponentEntity";
import {MultipleField} from "../../components/UI/MultipleField/MultipleField";
import {transformExtraFields, transformExtraFieldsToSend} from "../../components/ExtraFieldsHelpers";
import {FilesDropDown} from "../../components/FilesDropDown";
import {parseBackValidationErrors} from "../../helpers/parseBackValidationErrors";

export const tabsConfig = [
    {value:"information", label:"Основна інформація"},
    {value:"history", label:"Хронологія"},
    {value:"related", label:"Зв'язки"},
    {value:"files", label:"Файли"},
]

export const ContactView = () => {
    const history = useHistory()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search);
    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        account_id: null,
        account: null,
        birthdate: null,
        description: "",
        email: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        phone: "",
        owner_id: null,
        owner: null,
        personal_discount: 0,
        position: "",
        type_id: null,
        types: [],
        work_phone: "",
        photo: '',
        created_at: '',
        updated_at: '',
        editorTags: '',
        files: [],
        tasks: [],
        tags: [],
        extra_fields: {},
        begin_update_datetime: null,
        subscriber: null,
        thumbnail_id: null,
        img_id: null,
        tch: true
    })

    const getContactData = async () => {
        setLoading(true)

        try {
            const { status: prepareStatus, data: prepareData } = await myaxios.get(`ajax/contact/prepare`)

            //const { status: extraFieldsStatus, data: extraFieldsData } = await myaxios.get(`ajax/extra-fields?filter[entity][eq]=contact&expand=options`)

            const { data, status, response } = await getSingleContactService({
                id, expand: 'tags,tasks,Owner,account,extra_fields'
            })

            parseResponseError(response, history, '/contact');
            if(status === 200 && data.id) {
                setValues(({
                    ...values,
                    ...parseResponse(contactConfig, data, {}),
                    types: prepareData.types,
                    tasks: data.tasks,
                    tags: data.tags,
                    extra_fields_start: data.extra_fields,
                    //...transformExtraFields(extraFieldsData.items, data.extra_fields, 'contact_extra_fields'),
                    tch: true
                }))
            }
        } catch (e) { console.error('getContactDataError', e) }

        setLoading(false)
    }

    useEffect(() => {
        getContactData().then()
    }, [id])

    const saveItem = async () => {
        const { account_id, birthdate, description, email, first_name, last_name, middle_name, mobile_phone, owner_id, tags, phone,
            personal_discount, position, type_id, work_phone, photo, editorTags, updated_at } = values

        const errorsData = validationData(values, validationConfig({...contactConfig, ...values.extra_fields}))

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            toastifyWarning('Помилка валідації')
            return
        }
        setErrors({})

        //console.log('data', transformExtraFieldsToSend(values))

        setLoading(true)
        try {
            const { data, status, response } = await updateContactService({ id, data: {
                    account_id, description, email, first_name, last_name, middle_name, mobile_phone, owner_id, tags, phone,
                    position, work_phone, photo, birthdate: new Date(birthdate) || null, personal_discount: +personal_discount || 0, type_id: +type_id,
                    extra_fields: transformExtraFieldsToSend(values, 'contact_extra_fields', values.extra_fields).map(el => ({ ...el, contact_id: +id }))
                }})

            const parseResponseData = parseResponseError(response, history, '/contact');
            if(parseResponseData.validationErrors) { setErrors(parseResponseData.validationErrors) }

            if(status === 200 && data.id) {
                toastifySuccess('Запис успешно збережений')
                await getContactData()
            }

            if (response?.status === 422) {
                console.log(response)
                if (response.data.errors) setErrors(parseBackValidationErrors(response.data.errors))
            }
        } catch (e) {
            
        }
        setLoading(false)
    }

    const deleteItem = async () => {
        if(!window.confirm('Видалити элемент?')) return false

        setLoading(true)
        try {
            const { status, data } = await deleteContactService({ id })
            if(status === 200 || status === 204) {
                if(!data.canDelete) {
                    toastifySuccess('Контакт видалено успішно')
                    setValues(prevState => ({ ...prevState, tch: true }))
                    history.push('/contact')
                } else if(data.items && typeof data.items === 'object') {
                    setModalData({ type: 'canDelete', items: data.items })
                } else {
                    toastifyError('При видаленні щось пішло не так')
                }
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[contactConfig[key].entity_from] = null
            else obj[contactConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            tch: false
        })
    }

    return (
        <>
            {loading && <Loader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        {/*<TagsNew
                            editorTags={values.tags} entity={'contact'}
                            setValues={tags => setValues({ ...values, tags, tch: false })}
                        />*/}
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Дадати задачу'} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Видалити</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        {/*<div className="toogle-bottom">
                            <button onClick={() => setModalData({ type: 'qualification' })} className="mdn success btni ml5">Перевести в продажу</button>
                        </div>*/}
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Зберегти'} className={'ml5'} />
                    </div>
                </div>

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col" style={{ marginTop: "45px" }}>
                            <h3>Дані контакта</h3>
                            {/*<UploadAvatar
                                thumbnail_id={values.thumbnail_id}
                                img_id={values.img_id}
                                setValues={newData => setValues({ ...values, ...newData })}
                                related_id={id}
                                related_type="contact"
                            />*/}
                            <GenerateForm
                                config={{ ...contactConfig, ...values.extra_fields }}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            /> 
                            <div className="clear" />
                        </div>

                        {/*<AccountCard
                            account={values.account}
                            setDataValues={data => setValues(({ ...values, ...data, tch: false }))}
                        />*/}
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />

                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={200} classNames="my-node" unmountOnExit>
                                <InfoTab
                                    {...{ values, errors, disabled, changeFields, id, saveItem }}
                                    setContactFields={data => setValues({ ...values, contactFields: data, tch: false })}
                                    setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                />
                            </CSSTransition>
                            {/*<CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={200} classNames="my-node" unmountOnExit>
                                <History
                                    entity_type={'contact'}
                                    entity_id={id}
                                    isOpen={urlParams.get('layout') === tabsConfig[1].value}
                                    enitiObject={entity.contact.fArr}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={200} classNames="my-node" unmountOnExit>
                                <Related
                                    entity={'contact'} entity_id={id}
                                    sms={values.phone || values.work_phone || true}
                                    entity_data={{ contact: { name: values.first_name + ' ' + values.last_name }, contact_id: id }}
                                    opportunity order invoice document
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={200} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    entity_type={'contact'}
                                    entity_id={id}
                                />
                            </CSSTransition>*/}
                        </div>
                    </div>
                </div>
            </div>

            {/*<TaskModal
                id={modalData && modalData.id}
                close={() => setModalData(null)}
                index={modalData && modalData.index}
                task={(type, data, index) => {
                    setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) })
                }}
                related={{ id, type: 'contact' }}
                isOpen={modalData && modalData.type === 'task'}
            />*/}
        </>
    )
}