import './MultipleField.css'
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {useState, useRef} from "react";
import {CSSTransition} from "react-transition-group";

export const MultipleField = ({ options, value = [], onChange, label, style, className, valid, errorMessage, val = 'value', lab = 'label', itemName }) => {
    const [isOpen, setIsOpen] = useState(false)
    const htmlFor = `${Math.random()}`;
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const valuesData = value || []

    function values() {
        return options.map(el => ({ ...el, [val]: el[val] + '' })).filter(el => valuesData.includes(el[val] + ''))
    }

    return (
        <div style={style} className={className} ref={dropdownRef}>
            {label && <label htmlFor={htmlFor}>{label}</label>}
            <div className={'multiple-field__container sbox-autoselect-container'} style={{ position: 'relative', height: 'inherit' }}>
                <div style={{ display: "flex", flexWrap: 'wrap', marginTop: '-1px', marginLeft: '-3px', marginBottom: '-6px', minHeight: '24px' }}>
                    {values().map(item =>
                        <div
                            onClick={(e) => {
                                const result = value.filter(el => String(el) !== String(item[val]))
                                onChange([...valuesData, item[val]], {...e, name: itemName, target: { ...e.target, value: result.join(',') }})
                            }}
                            className={'multiple-field__option'}
                            key={item[val]}>{item[lab]}
                        </div>)}
                </div>
                {
                    (options || []).map(el => ({ ...el, [val]: el[val] + '' })).filter(el => !valuesData.includes(el[val])).length > 0 &&
                    <em style={{ top: 2, right: 2 }} onClick={() => setIsOpen(!isOpen)} className="AutoSelectParent__child false">❮</em>
                }
                <CSSTransition in={isOpen && (options || []).map(el => ({ ...el, [val]: el[val] + '' })).filter(el => !valuesData.includes(el[val])).length > 0} timeout={100} classNames="my-node" unmountOnExit>
                    <div className="sbox-autoselect__dropdown scrollbar">
                        {
                            (options || []).map(el => ({ ...el, [val]: el[val] + '' })).filter(el => !valuesData.includes(el[val])).map(item => (
                                <div
                                    key={item[val]}
                                    className="sbox-autoselect__dropdown-item"
                                    onClick={(e) => {
                                        onChange([...valuesData, item[val]], {...e, name: itemName, target: { ...e.target, value: [...valuesData, item[val]].join(',') }})
                                    }}
                                >{item[lab]}</div>
                            ))
                        }
                    </div>
                </CSSTransition>
            </div>
            {!valid && <span className="errorMsg">{(errorMessage) || "Поле обов\'язкове"}</span>}
        </div>
    )
}