import {NavLink} from "react-router-dom";
import {NewList} from "../../../../components/NewList/NewList";
import React from "react";

export const ShopList = (props) => {
    return (
        <NewList
            { ...props }
            entity={'shop'}
            requiredFields={'id,name'}
            additionalFieldsExists={false}
            isFilterExists={false}
            headingColumns={['Назва магазину']}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('shop')} className="open-modal-button btni">Додати магазин</button>}
            contextNav={{ edit: false, task: false, print: false, delete: true }}
            filterFields={[]}
            onCreatedResult={'update-list'}
            isDoubleClick={false}
            isPagination={true}
            RenderColumn={(item) => (
                <>
                    <td>
                        {item.name}
                    </td>
                </>
            )}
        />
    )
}