import {NavLink} from "react-router-dom";
import {NewList} from "../../../../components/NewList/NewList";
import React from "react";

export const UserList = () => {
    return (
        <NewList
            entity={'user'}
            requiredFields={'id,name,email,phone,role_id'}
            additionalFieldsExists={false}
            isFilterExists={false}
            headingColumns={['ФІО', 'Email', 'Телефон', 'Роль']}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('user')} className="open-modal-button btni">Додати користувача</button>}
            contextNav={{ edit: false, task: false, print: false, delete: true }}
            filterFields={[]}
            onCreatedResult={'update-list'}
            doubleClickLink={'/settings/user'}
            isPagination
            requiredExpand={"Role"}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/settings/user/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td>
                        <span>{item.email}</span>
                    </td>
                    <td>
                        <span>{item.phone}</span>
                    </td>
                    <td>
                        <span>{item?.role?.name}</span>
                    </td>
                </>
            )}
        />
    )
}