import {ListSettingsNew} from "../../../components/DDlist/ListSettingsNew";

export const ContactSettings = () => {
    return (
        <div>
            <ListSettingsNew
                top={0}
                type={'ct_type'}
            />
        </div>
    )
}