import React from "react";

export const InfoTable = ({ arr = [], renderData }) => {
    return (
        <div className="tWrap">
            <table className="stagesTable body-table" style={{ borderSpacing: 0 }}>
                <tbody>
                {
                    renderData && arr.map(item => renderData(item))
                }
                {
                    !renderData && arr.map(({ value, label, render }) => (
                        <tr key={value}>
                            <td>{value}</td>
                            <td>{render ? render() : label}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}