import React, {useRef, useState} from "react";
import {controlErrorValid} from "../../UI/formValid";
import {CSSTransition} from "react-transition-group";
import {Options} from "../../UI/Options";
import Input from "../../UI/Input";
import ModAutoSelect from "../../UI/ModAutoSelect";
import DateTime from "../../UI/DateTime";
import Myselect from "../../UI/Select";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {FilterRangeDateInput} from "./UI/FilterRangeDateInput";
import {FilterRangeNumberInput} from "./UI/FilterRangeNumberInput";

export const operator = {
    number: [
        {value:'eq', label:'Дорівнює'},
        {value:'neq', label:'Не дорівнює'},
        {value:'gt', label:'Більше'},
        {value:'lt', label:'Меньше'},
        {value:'between', label:'Діапазон'},
        /*{value:'<=', label:'Меньше равно'},
         {value:'>=', label:'Больше равно'},*/
    ],
    text: [
        {value:'like', label:'Містить'},
        {value:'nlike', label:'Не містить'},
    ],
    select: [
        {value:'like', label:'Містить'},
        {value:'nlike', label:'Не містить'},
    ],
    date: [
        /*{value:'like', label:'Равно'},
        {value:'nlike', label:'Не равно'},*/
        {value:'lt', label:'Раніше'},
        {value:'gt', label:'Пізніше'},
        /*{value:'between', label:'Період'},*/
    ],
    lookup: [
        {value:'like', label:'Містить'},
        {value:'nlike', label:'Не містить'},
    ],
    entity: [
        {value:'like', label:'Містить'},
        {value:'nlike', label:'Не містить'},
    ],
    tags: [
        {value:'like', label:'Містить'},
        {value:'nlike', label:'Не містить'},
    ],
    products: [
        {value:'like', label:'Містить'},
        {value:'nlike', label:'Не містить'},
    ]
}

function formatDate(date){
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return yy + '-' + mm + '-' + dd;
    }
}

export const NewFilterItem = ({ filterParams, filterFields, setFilterParams, saveFilter }) => {
    const [valid, setValid] = useState('')

    const clearField = () => {
        setFilterParams({
            logic: 'or',
            fieldConfig: null,
            field: '',
            operator: '',
            value: '',
            entity: null
        })
    }

    const validation = () => {
        if(filterParams.fieldConfig.fieldType !== 'number') {
            saveFilter([filterParams])
            clearField()
            return;
        }
        if (filterParams.fieldConfig.fieldType === 'number' && filterParams.operator === "between") {
            saveFilter([filterParams])
            clearField()
            return;
        }
        const validData = controlErrorValid(filterParams.value, { required: true, number: true })
        if(validData) {
            setValid(String(validData))
        } else {
            setValid('')
            clearField()
            saveFilter([filterParams])
        }
    }

    const dropdownRef = useRef(null)
    const inputRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => clearField() })

    return (
        <CSSTransition in={!!filterParams.field}  timeout={200} classNames="my-node" unmountOnExit>
            <div className="create-filter-modal sbform" ref={dropdownRef}>
                <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {filterParams.fieldConfig.label}:
                    <Myselect
                        options={[{ value: 'or', label: 'or' }, { value: 'and', label: 'and' }]}
                        onChange={e => setFilterParams({logic: e.target.value})}
                        value={filterParams.logic}
                        style={{ height: '25px', padding: 0 }}
                    />
                </p>
                <Options
                    options={operator[filterParams.fieldConfig.fieldType]}
                    onClick={op => setFilterParams({operator: op.value})}
                    active={filterParams.operator}
                />
                <ValueItem
                    filterParams={filterParams}
                    setFilterParams={data => {
                        setFilterParams(data)
                    }}
                    submitData={validation}
                    updateData={() => inputRef.current.focus()}
                />
                <div className={'create-filter-modal-valid'}>{valid}</div>
                <input

                    ref={inputRef} className={'input-hidden'}
                    onKeyDown={(e) => e.keyCode === 13 && validation()}
                />
                <div className="create-filter-modal-actions">
                    <button
                        onClick={clearField}
                        className={'create-filter-modal-actions-cancel'}>Відмінити</button>
                    <button
                        onClick={validation}
                        disabled={!String(filterParams.value).trim().length > 0}
                        className={'create-filter-modal-actions-save'}>Зберегти</button>
                </div>
            </div>
        </CSSTransition>
    )
}


const ValueItem = ({ filterParams, setFilterParams, submitData, updateData }) => {
    if (filterParams.fieldConfig.fieldType === 'text' || filterParams.fieldConfig.fieldType === 'number' || filterParams.fieldConfig.fieldType === 'url') {
        if (filterParams.fieldConfig.fieldType === 'number' && filterParams.operator === "between") {
            return <FilterRangeNumberInput
                value={filterParams.value}
                onChange={val => setFilterParams({ value: val })}
            />
        }
        return (
            <Input
                type={filterParams.fieldConfig.fieldType}
                value={filterParams.value}
                onChange={e => setFilterParams({ value: e.target.value })}
                onKeyDown={e => e.keyCode === 13 && (filterParams.value || '').trim().length > 0 && submitData()}
                shouldValidate
                valid
            />
        )
    } else if(
        filterParams.fieldConfig.fieldType === 'lookup' || filterParams.fieldConfig.fieldType === 'entity' ||
        filterParams.fieldConfig.fieldType === 'products' || filterParams.fieldConfig.fieldType === 'tags'
    ) {
        return (
            <div style={{ position: 'relative' }}>
                <div className="clear" />
                <ModAutoSelect
                    link={filterParams.fieldConfig.filterLink}
                    minLength={filterParams.fieldConfig.filterLength}
                    result={filterParams.fieldConfig.filterSearch}
                    event="account"
                    valid
                    addType="account"
                    entityName={filterParams.fieldConfig.filterSearch}
                    entity={filterParams.entity}
                    updateData={(id,arr) => {
                        setFilterParams({ value: id, entity: arr })
                        updateData()
                    }}
                    unLink={() => setFilterParams({ value: '', entity: null })}
                />
                <div className="clear" />
            </div>
        )
    } else if(filterParams.fieldConfig.fieldType === 'date') {
        if (filterParams.operator === "between") {
            return <FilterRangeDateInput
                value={filterParams.value}
                onChange={val => setFilterParams({ value: val })}
            />
        }
        return (
            <DateTime
                value={filterParams.value}
                valid
                isClearable={false}
                handleChange={e => {
                    e ? setFilterParams({ value: formatDate(e) }) : setFilterParams({ value: '' })
                }}
                dateFormat="dd-MMMM-yyyy"
                todayButton
            />
        )
    } else if(filterParams.fieldConfig.fieldType === 'select') {
        return (
            <Myselect
                empty
                value={filterParams.value}
                options={filterParams.fieldConfig.options}
                onChange={e => setFilterParams({ value: e.target.value })}
                val="id"
                lab="label"
            />
        )
    } else {
        return <>Немає значення</>
    }
}