import is from 'is_js';

export const validationData = (values, validation) => {
    let errorsData = {}
    Object.keys(validation).map(key => {
        const errorMessage = controlErrorValid(values[key], validation[key])
        if(errorMessage) {
            errorsData[key] = errorMessage
        }
    })
    return errorsData
}

export function controlErrorValid (value, validation) {
    if (!validation) {
        return false
    }

    if (validation.required) {
        if(!value) {
            return "Поле обов'язкове для заповнення"
        }
        if (!String(value)) {
            return "Поле обов'язкове для заповнення"
        }
        if (typeof value === 'string' && value.trim() === '') {
            return "Поле обов'язкове для заповнення"
        } else if(typeof value === 'number' && String(value).length === 0) {
            return "Поле обов'язкове для заповнення"
        }
    }

    if (validation.email && typeof value === 'string' && value.trim() !== '' && !is.email(value)) {
        return "Введіть правильний email"
    }

    if (validation.minLength && String(value).trim() !== '' && String(value).trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').length < validation.minLength) {
        return `Мінімальний розмір поля ${validation.minLength}`
    }

    if (validation.maxLength && String(value).trim() !== '' && String(value).trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').length > validation.maxLength) {
        return `Максимальний розмір поля ${validation.maxLength}`
    }

    if (validation.number && !is.number(Number(value))) {
        return "Поле має бути числом"
    }

    if (typeof validation.minNumber == "number" && Number(value) < +validation.minNumber) {
        return `значення не може бути меншим ${validation.minNumber}`
    }

    if (typeof validation.maxNumber == "number" && Number(value) > validation.maxNumber) {
        return `значення не може бути більше ${validation.maxNumber}`
    }

    if (validation.url && typeof value === 'string' && value.trim() !== '' && !is.url(value)) {
        return "Введіть правильний url"
    }

    if (validation.reg && value.trim() !== '') {
        const re = new RegExp(validation.reg)
        if(!re.exec(value)) {
            return "Введіть правильне значення згідно з прикладом"
        }
    }

    return false
}

const formValid = (value, validation, obj) => {
    //console.log("value",value, obj)
    if (!validation) {
        return true
    }

    if (value === null) {
        value = "";
        console.log("Помилка валідації. У поля значення NULL")
    }

    if(value){
        value = value.toString();
    }
    let isValid = true;

    if (validation.emptySelect) {
        isValid = value !== null && value !== "" && isValid
    }

    if (validation.required) {
        if(!value){
            return false
        } else {
            isValid = value.trim() !== '' && isValid
        }
    }

    if (validation.email && value.trim() !== '') {
        isValid = is.email(value) && isValid
    }

    if (validation.minLength) {
        isValid = value.length >= validation.minLength && isValid
    }

    if (validation.number && value.trim() !== '') {
        isValid = is.number(+value) && isValid
    }

    if (validation.notRequiredMinLength && value.trim() !== '') {
        isValid = value.length >= validation.notRequiredMinLength && isValid
    }

    if (validation.ip && value.trim() !== '') {
        isValid = is.ip(value) && isValid
    }

    if (validation.url && value.trim() !== '') {
        isValid = is.url(value) && isValid
    }

    if (validation.website && value.trim() !== '') {
        isValid = is.url(value) && isValid
    }

    if (validation.password && value.trim() !== '') {
        isValid = value.length >= validation.notRequiredMinLength && isValid
    }

    if (validation.reg && value.trim() !== '') {
        const re = new RegExp(validation.reg)
        if(!re.exec(value)) {
            return "Введіть коректне значення згідно прикладу"
        }
    }

    /*if (validation.phone && value.trim() !== '') {
        let phoneno = /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
        isValid = value.match(phoneno) && isValid
    }*/


    return isValid
};


export default formValid;