import {myaxios} from "../axios";

export const getTaskPrepareService = async () => await myaxios.get(`/ajax/task/prepare`);

export const getSingleTaskService = async ({id, expand}) => await myaxios.get(`/ajax/task/${id}${expand}`);

export const createTaskService = async ({data}) => await myaxios.post(`/ajax/task?expand=owner,status`, data);

export const updateTaskService = async ({data, id}) => await myaxios.put(`/ajax/task/${id}?expand=owner,status`, data);

export const deleteTaskService = async ({ id }) => await myaxios.delete(`/ajax/task/${id}`)