import Input from "../../../UI/Input";
import {useEffect, useRef, useState} from "react";
import {formatDate} from "../../../cardViewComponents/formatDate";
import {toastifyWarning} from "../../../toastify/toastify";

export const FilterRangeDateInput = ({ onChange, value }) => {
    useEffect(() => {
        const today = formatDate(new Date())
        const tomorrow = formatDate((new Date()).setDate((new Date()).getDate() + 1))
        onChange(`${today}/${tomorrow}`)
    }, [])

    return (
        <div style={{ display: 'flex' }}>
            <Input
                className={'w50'}
                label={'Від дати'}
                type={'date'}
                value={value.split('/')[0]}
                onChange={e => {
                    onChange(`${formatDate(e.target.value)}/${value.split('/')[1]}`)
                }}
            />
            <Input
                className={'w50'}
                label={'До дати'}
                type={'date'}
                value={value.split('/')[1]}
                onChange={e => {
                    onChange(`${value.split('/')[0]}/${formatDate(e.target.value)}`)
                }}
            />
        </div>
    )
}