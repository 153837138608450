import {SettingsRoute} from "./containers/settings/Settings";
import {ListContactNew} from "./containers/contacts/ListContactNew";
import {ContactView} from "./containers/contacts/ContactView";

export const router = [
    {
        path: "/settings",
        Component: SettingsRoute,
        exact: false,
    },
    {
        path: "/contact/:id",
        Component: ContactView,
        exact: false,
    },
    {
        path: "/contact",
        Component: ListContactNew,
        exact: false,
    },
]