import {createContext, useEffect, useRef, useState} from "react";
import {toastifySuccess} from "./components/toastify/toastify";
import Push from 'push.js';

export const ContextProvider = createContext(null)

export const Context = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [user, setUser] = useState(null)
    const [companyId, setCompanyId] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [accessEmails, setAccessEmails] = useState([])
    const [notificationsCount, setNotificationsCount] = useState(0)
    const [rules, setRules] = useState({})
    const [integrations, setIntegrations] = useState({
        sms: false,
        checkbox: 0,
        nova_poshta: 0
    })
    let websocket = null;

    useEffect(() => {
        if (!user) {
            return
        }
        // Создание нового WebSocket соединения при монтировании компонента
        websocket = new WebSocket('wss://ws.oneweb.ua');
        // websocket = new WebSocket('ws://localhost:9001');

        websocket.onopen = () => {
            websocket.send(JSON.stringify({ action: 'register', clientId: `test4_${user.id}`, token: localStorage.getItem('') }));
            console.log('WebSocket connection established');
        }

        websocket.onmessage = (event) => {
            const messageJSON = JSON.parse(event.data)

            if (messageJSON.type === 'notification') {
                toastifySuccess(messageJSON.data)
                setNotificationsCount(notificationsCount + 1)
                sendNotification('Нове повідомлення', messageJSON.data, `http://localhost:3000/${messageJSON.entity}/${messageJSON.entity_id}`).then()
            }

            /*const messageBlob = event.data;
            messageBlob.text().then((messageText) => {
                const messageJSON = JSON.parse(messageText);
                if (messageJSON.type === 'notification') {
                    toastifySuccess(messageJSON.data)
                    setNotificationsCount(notificationsCount + 1)
                    sendNotification('Нове повідомлення', messageJSON.data, `http://localhost:3000/${messageJSON.entity}/${messageJSON.entity_id}`).then()
                }
                console.log(`Получено сообщение от сервера в формате JSON:`, messageJSON);
            });*/
        };

        websocket.onerror = (error) => console.log('WebSocket error: ', error);

        websocket.onclose = () => console.log('WebSocket connection closed');

        setSocket(websocket)

        // Очистка при размонтировании компонента
        return () => {
            setSocket(null)
            websocket.close();
        };
    }, [user]);

    const requestNotificationPermission = async () => {
        //const permission = await Push.Permission.request();
        //console.log('Notification permission:', permission);
    };

    const sendNotification = async (title, body, link) => {
        await Push.create(title, {
            body: body,
            icon: 'path/to/icon.png',
            timeout: 5000,
            onClick: () => {
                window.location.href = link
            },
        });
    };

    useEffect(() => {
        requestNotificationPermission().then();
        //connectToWebsocket()
        return () => { socket && socket.close(); };
    }, [user]);


    return (
        <>
            {/*<button onClick={() => socket.send('test')}>123</button>*/}
            <ContextProvider.Provider value={{
                user, setUser,
                currencies, setCurrencies,
                notificationsCount, setNotificationsCount,
                accessEmails, setAccessEmails,
                integrations, setIntegrations,
                rules, setRules,
                companyId, setCompanyId
            }}>
                {children}
            </ContextProvider.Provider>
        </>

    )
}

/*
function WebSocketClient() {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = new WebSocket('ws://localhost:8080');
        setSocket(newSocket);

        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        const handleOpen = (event) => {
            console.log('Соединение с сервером установлено');
            socket.send('Привет, сервер!');
        };

        const handleMessage = (event) => {
            console.log(`Получено сообщение от сервера: ${event.data}`);
            toastifySuccess(event?.data)
        };

        const handleClose = (event) => {
            console.log('Соединение с сервером закрыто');
        };

        const handleError = (event) => {
            console.log(`Ошибка соединения с сервером: ${event.message}`);
        };

        socket.addEventListener('open', handleOpen);
        socket.addEventListener('message', handleMessage);
        socket.addEventListener('close', handleClose);
        socket.addEventListener('error', handleError);

        return () => {
            socket.removeEventListener('open', handleOpen);
            socket.removeEventListener('message', handleMessage);
            socket.removeEventListener('close', handleClose);
            socket.removeEventListener('error', handleError);
        };
    }, [socket]);

    return (
        <div />
    );
}

export default WebSocketClient;*/
