import React, {useEffect} from "react";
import Modal from "../components/Modal/Modal";
import {useState} from "react";
import {FullModal} from "../components/Modal/FullModal";
import {NavLink} from "react-router-dom";
import {myaxios} from "../services/axios";
import {toastifyError} from "../components/toastify/toastify";

const convertItems = (obj) => {
    let arr = []

    Object.keys(obj).map(key => {
        if(+obj[key].totalCount > 0) {
            arr = [...arr, ...obj[key].items.map(item => ({ key, ...item }))]
        }
    })
    return arr
}

const switchType = (key) => {
    switch (key) {
        case 'invoices': return 'Счет';
        case 'documents': return 'Документ';
        case 'cases': return 'Обращение';
        case 'opportunities': return 'Сделка';
        case 'orders': return 'Заказ';
        case 'shipments': return 'Отгрузка';
        default: return 'Не определено'
    }
}

const switchNumber = (key) => {
    switch (key) {
        case 'invoices': return 'invoice';
        case 'documents': return 'document';
        case 'cases': return 'case';
        case 'opportunities': return 'opportunity';
        case 'orders': return 'order';
        case 'shipments': return 'stock/shipment';
        default: return 'Не определено'
    }
}

export const CanDelete = ({ entity, delParent, name, items, close, isOpen, id, history }) => {
    const [loading, setLoading] = useState(false)
    const [modalItems, setItems] = useState([])
    const [elementId, setElementId] = useState(null)
    const [modalData, setModalData] = useState(null)

    useEffect(() => {
        if(isOpen) setItems(convertItems(items))
        else setItems([])

        if(id) setElementId(id)
    }, [isOpen, entity, id])

    const deleteItem = async (link, key, item_id, hideCheck) => {
        /*if(entity === 'product') {
            await unLinkProduct(link, key, item_id, hideCheck)
            return;
        }*/

        if(!hideCheck && !window.confirm('Удалить элемент?')) {
            return false;
        }
        const { status, data } = await myaxios.delete(`/ajax/${switchNumber(key)}/${item_id}`)
        if(status === 200 || status === 204) {
            if(!data.canDelete) {
                if(modalItems.length <= 1) {
                    close()
                    setTimeout(() => delParent(item_id), 200)
                } else setItems(modalItems.filter(item => item.link !== link))
            } else if(data.items && typeof data.items === 'object') {
                setModalData({ type: 'canDelete', items: data.items, key, link, id })
            } else {
                toastifyError('При видаленні щось пішло не так')
            }
        }
    }

    return (
        <FullModal isOpen={isOpen} close={close} loading={loading}>
            <div className={'can-delete'}>
                <div className={'can-delete-heading'}>
                    <h1>Невозможно удалить {name}</h1>
                    <p>Обнаружены записи, связанные с текущей. Чтобы удалить {name} удалите все дочерние записи</p>
                </div>
                <div className="can-delete-list ">
                    <div className="contact-page sp-table">
                        <table>
                            <thead>
                                <th style={{ textAlign: 'left', paddingBottom: 0 }} className="fst-tr">Тип</th>
                                <th style={{ textAlign: 'left', paddingBottom: 0 }} className="fst-tr">Название</th>
                                <th style={{ textAlign: 'left', paddingBottom: 0 }} className="fst-tr" />
                            </thead>
                            <tbody>
                            {
                                modalItems.map((item, i) => (
                                    <tr className="pull-up" key={i}>
                                        <td><a target={'_blank'} href={item.link} >{switchType(item.key)}</a></td>
                                        <td><a target={'_blank'} href={item.link} >{item.name}</a></td>
                                        {/*<td><a onClick={() => deleteItem(item.link, item.key, item.id)} className="is">✕</a></td>*/}
                                        <td><a target={'_blank'} href={item.link} className="is"><i className="flaticon-more" /></a></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <CanDelete
                    isOpen={modalData && modalData.type === 'canDelete'} close={() => setModalData(null)}
                    items={modalData && modalData.items} entity={'product'} delParent={(new_id) => {
                        // console.log('555555', new_id, modalData)
                        // deleteItem(modalData.link, modalData.key, new_id, true)
                        setModalData(null)
                }} name={modalData && modalData.key && switchType(modalData.key)} id={modalData && modalData.id} history={history}
                />
            </div>
        </FullModal>
    )
}