import React, {useEffect, useState} from "react";
import {validationData} from "../components/UI/formValid";
import Input from "../components/UI/Input";
import Modal from "../components/Modal";
import {myaxios} from "../services/axios";
import {toastifySuccess} from "../components/toastify/toastify";
import Textarea from "../components/UI/Textarea";
import {parseBackValidationErrors} from "../helpers/parseBackValidationErrors";
import {useHistory} from "react-router-dom";

const validationRules = {
    name: { required: true, maxLength: 30 },
    description: { maxLength: 255 },
}

export const CreateRole = ({ close, save }) => {
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        description: '',
        tch: true
    })

    const closeModal = () => { setIsOpen(false); setTimeout(() => { save(); close() }, 200) }

    useEffect(() => {
        setIsOpen(true)
    }, [])

    const saveData = async () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data, request } = await myaxios.post(`ajax/role`, values)

            if (status === 201 && data.id) {
                toastifySuccess('Роль успішно створена')
                closeModal()
            }

            if (request?.status === 422) {
                const res = JSON.parse(request.response)
                if (res.errors) setErrors(parseBackValidationErrors(res.errors))
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }


    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Створити роль'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <Input
                    label={'Назва'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
                <Textarea
                    label={'Опис'}
                    value={values.description}
                    onChange={e => setValues({...values, description: e.target.value, tch: false })}
                    errorMessage={errors.description} valid={!errors.description} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                    rows={6}
                />
            </div>
        </Modal>
    )
}