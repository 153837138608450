import React, {useContext, useEffect, useState} from "react";
import './settings.css'
import {NavLink, Redirect, Route, Switch, useLocation} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {RoleList} from "./routes/role/RoleList";
import {RoleView} from "./routes/role/RoleView";
import {UserList} from "./routes/user/userList";
import {UserView} from "./routes/user/UserView";
import {ShopList} from "./routes/shop/ShopList";
import {ContactSettings} from "./routes/ContactSettings";
import {ContextProvider} from "../../Context";
import {CompanySettings} from "./routes/settings/CompanySettings";

const settingsRoutes = [
    {
        path: '/settings/role',
        Component: RoleList,
        exact: true
    },
    {
        path: '/settings/role/:id',
        Component: RoleView,
        exact: true
    },
    {
        path: '/settings/user',
        Component: UserList,
        exact: true
    },
    {
        path: '/settings/user/:id',
        Component: UserView,
        exact: true
    },
    {
        path: '/settings/shop',
        Component: ShopList,
        exact: true
    },
    {
        path: '/settings/contact',
        Component: ContactSettings,
        exact: true
    },
    {
        path: '/settings/currency',
        Component: CompanySettings,
        exact: true
    },
]

export const SettingsRoute = (props) => {
    const location = useLocation()
    const { rules } = useContext(ContextProvider)

    if (Object.keys(rules).length === 0) {
        return <></>
    }

    return (
        <div className={'settings-route'}>
            <div className="settings-route__nav">
                {/*<NavLink to={'/settings/profile'}><i className={'flaticon-profile'} /><span>Профіль</span></NavLink>
                <NavLink to={'/settings/notifications'}><i className={'flaticon-music-2'} /><span>Сповіщення</span></NavLink>}*/}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/shop'}><i className={'flaticon-map'} /><span>Магазини</span></NavLink>}
                {rules['role'].update === "allowed" && <NavLink to={'/settings/role'}><i className={'flaticon-multimedia-1'}/><span>Ролі</span></NavLink>}
                {rules['user'].update === "allowed" && <NavLink to={'/settings/user'}><i className={'flaticon-users'}/><span>Користувачі</span></NavLink>}
                {/*{rules['email'].view === "allowed" && <NavLink to={'/settings/email'}><i className={'flaticon-email'}/><span>Email</span></NavLink>}*/}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/currency'}><i className={'flaticon-cogwheel-1'}/><span>Валюта</span></NavLink>}
                {/*{rules['integrations'].update === "allowed" && <NavLink to={'/settings/integrations'}><i className={'flaticon-network'}/><span>Інтеграції</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/source'}><i
                    className={'flaticon-user-settings'}/><span>Джерела заявок</span></NavLink>}*/}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/contact'}><i className={'flaticon-user'}/><span>Контакти</span></NavLink>}
                {/*{rules['settings'].update === "allowed" && <NavLink to={'/settings/account'}><i className={'flaticon-book'}/><span>Контрагенти</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/task'}><i className={'flaticon-list-3'}/><span>Задачі</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/lead'}><i className={'flaticon-user-ok'}/><span>Ліди</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/price-book'}><i
                    className={'flaticon-clipboard'}/><span>Прайс-листи</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/product-category'}><i className={'flaticon-interface-7'}/><span>Категорії продуктів</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/product'}><i className={'flaticon-app'}/><span>Продукти</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/order'}><i className={'flaticon-cart'}/><span>Замовлення</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/opportunity'}><i className={'flaticon-share'}/><span>Угоди</span></NavLink>}
                {(rules['balance'].update === "allowed" || rules['settings'].update === "allowed") && <NavLink to={'/settings/finance'}><i className={'flaticon-coins'}/><span>Фінанси</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/document'}><i
                    className={'flaticon-interface-9'}/><span>Документи</span></NavLink>}
                {rules['document_template'].view === "allowed" && <NavLink to={'/settings/document-template'}><i className={'flaticon-technology-2'}/><span>Шаблони документів</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/tags'}><i className={'flaticon-interface-10'}/><span>Теги</span></NavLink>}
                {rules['settings'].update === "allowed" && <NavLink to={'/settings/stock'}><i className={'flaticon-open-box'}/><span>Склад</span></NavLink>}
                <NavLink to={'/settings/knowledge-base'}><i className={'flaticon-apps'}/><span>База знань</span></NavLink>*/}
            </div>
            <div className="settings-route__component">
                <TransitionGroup className={'transition-group-screen'}>
                    <CSSTransition
                        key={location}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames={'slide'}
                    >
                        <Switch>
                            {
                                settingsRoutes.map(({ path, Component, exact }) => (
                                    <Route
                                        key={path}
                                        exact={exact}
                                        path={path}
                                        component={Component}
                                        render={() => <Component {...props} />}
                                    />
                                ))
                            }
                            <Redirect exact from='/settings/' to={'/settings/profile'}/>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    )
}