import React, {useEffect, useState} from "react";
import {validationData} from "../../../../components/UI/formValid";
import Input from "../../../../components/UI/Input";
import Modal from "../../../../components/Modal";
import {myaxios} from "../../../../services/axios";
import {parseBackValidationErrors} from "../../../../helpers/parseBackValidationErrors";

const validationRules = {
    name: { required: true, maxLength: 255 },
}

export const CreateShop = ({ close, save }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        tch: true
    })

    const closeModal = () => { setIsOpen(false); setTimeout(() => { save(); close() }, 200) }

    useEffect(() => {
        setIsOpen(true)
    }, [])

    const saveData = async () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data, request } = await myaxios.post(`ajax/shop`, values)
            if (status === 201 && data.id) {
                save()
                closeModal()
            }
            if (request?.status === 422) {
                const res = JSON.parse(request.response)
                if (res.errors) setErrors(parseBackValidationErrors(res.errors))
            }
        } catch (e) {

        }
        setLoading(false)
    }


    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Створити магазин'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <Input
                    label={'Назва'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
            </div>
        </Modal>
    )
}