import React, {useEffect, useState} from "react";
import {ListSettingsModal, lookupItemConfig} from "./ListSettingsModal";
import {ModalLoader} from "../Loader";
import {myaxios} from "../../services/axios";
import {toastifyError, toastifySuccess, toastifyWarning} from "../toastify/toastify";

export const ListSettingsNew = ({ type, top = 50 }) => {
    const [loading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [list, setList] = useState([])

    const getItems = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`/ajax/lookup?type=${type}&order=order`)
            if(status === 200 && data) {
                setList(data.items)
            }
        } catch (e) {}
        setIsOpen(false)
        setLoading(false)
    }
    
    useEffect(() => {
        getItems().then()
    }, [])

    const deleteElement = async (id) => {
        if (!window.confirm('Ви впевнені?')) return false

        setLoading(true)
        try {
            const { status } = await myaxios.delete(`ajax/lookup/${id}`)
            if (status === 204) {
                await getItems()
                toastifySuccess('Елемент видалений')
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return (
        <div className={'list-settings-new'} style={{ position: 'relative', marginTop: `${top}px` }}>
            {loading && <ModalLoader />}
            <div className="second-table " style={{ marginTop: '10px' }}>
                <h2 style={{ fontSize: '14px', marginBottom: '3px' }}>{lookupItemConfig(type).title}</h2>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th style={{ textAlign: 'center' }}>ID</th>
                        <th>Назва</th>
                        {
                            lookupItemConfig(type).fields && lookupItemConfig(type).fields.map(th => (
                                <th key={th.value}>{th.label}</th>
                            ))``
                        }
                        {
                            lookupItemConfig(type)?.config?.secondField && <th>{lookupItemConfig(type)?.config?.secondField?.label}</th>
                        }
                        {!lookupItemConfig(type).config.hideColor && <th />}
                        <th style={{ width: '30px'}} align={'center'} />
                    </tr>
                    {
                        list.map((item) => (
                            <tr key={item.id}>
                                <td style={{ width: '30px', textAlign: 'center' }}>{item.id}</td>
                                <td>{lookupItemConfig(type).field ? item[lookupItemConfig(type).field] : item.name}</td>
                                {
                                    lookupItemConfig(type)?.config?.secondField && <td>
                                        {item[lookupItemConfig(type)?.config?.secondField?.value]}
                                    </td>
                                }
                                {!lookupItemConfig(type).config.hideColor &&
                                    <td style={{ width: '30px', padding: '5px' }}><div style={{ background: item.color }} className={'static-color-item'} /></td>}
                                <td align={'center'}>
                                    <div style={{ textAlign: 'center' }}>
                                        {
                                            list.length > 1 && !item.is_system ?
                                                <i
                                                    onClick={() => deleteElement(item.id)}
                                                    className={'flaticon-delete-2 drag-list__item-delete'}
                                                /> :
                                                <i
                                                    onClick={() => toastifyWarning('Системне поле видалити неможливо')}
                                                    style={{ color: 'rgb(105 105 105)', fontSize: '20px' }}
                                                    className={'flaticon-lock-1 drag-list__item-delete'}

                                                />
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </table>
                <button onClick={(e) => {
                    e.preventDefault()
                    setIsOpen(true)
                }} className="success btni mt10">Змінити</button>
            </div>
            <ListSettingsModal
                isOpen={isOpen}
                type={type}
                save={getItems}
                close={() => setIsOpen(false)}
            />
        </div>
    )
}