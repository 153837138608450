import {Component, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {myaxios} from "../../../../services/axios";
import Loader from "../../../../components/Loader";
import {toastifySuccess} from "../../../../components/toastify/toastify";
import Input from "../../../../components/UI/Input";
import Switch from "../../../../components/UI/Switch";

const setLabel = (name) => {
    switch(name){
        case "shop": return { name: "Магазини", type: 'radio' };
        case "role": return { name: "Ролі", type: 'radio' };
        case "user": return { name: "Користувачі", type: 'radio' };
        case "contact": return { name: "Контакти", type: 'radio' };
        case "task": return { name: "Задачі", type: 'radio' };
        case "invoice": return { name: "Рахунки", type: 'radio' };
        case "account": return { name: "Контрагенти", type: 'radio' };
        case "opportunity": return { name: "Угоди", type: 'radio' };
        case "project": return { name: "Проекти", type: 'radio' };
        case "mail": return { name: "Пошта", type: 'radio' };
        case "product": return { name: "Продукти", type: 'radio' };
        case "order": return { name: "Замовлення", type: 'radio' };
        case "lead": return { name: "Ліди", type: 'radio' };
        case "document": return { name: "Документи", type: 'radio' };
        case "expense": return { name: "Витрати", type: 'radio' };
        case "payment": return { name: "Платежі", type: 'radio' };
        case "case": return { name: "Звернення", type: 'radio' };
        case "purchase": return { name: "Закупівлі", type: 'radio' };
        case "replenish": return { name: "Надходження", type: 'radio' };
        case "shipment": return { name: "Відвантаження", type: 'radio' };
        case "consumption": return { name: "Списання", type: 'radio' };
        case "transfer": return { name: "Переміщення", type: 'radio' };
        case "settings": return { name: "Налаштування", type: 'radio' };
        case "fin_transfer": return { name: "Фінансові перекази", type: 'radio' };
        case "stock": return { name: "Склади", type: 'radio' };
        case "order_return": return { name: "Повернення", type: 'radio' };
        case "inventory": return { name: "Інвентаризації", type: 'radio' };
        case "balance": return { name: "Гаманці", type: 'radio' };
        case "email": return { name: "Email", type: 'radio' };
        case "document_template": return { name: "Шаблони документів", type: 'radio' };
        case "balance_admin": return { name: "Перегляд балансу", type: 'switch', key: 'view' };
        case "integrations": return { name: "Інтеграції", type: 'switch', key: 'update' };
        case "import_data": return { name: "Імпорт", type: 'switch', key: 'update' };
        default: return { name: name }
    }
};

export const RoleView = () => {
    const { id } = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [roleData, setRoleData] = useState(null)
    const [permissions, setPermissions] = useState({})
    const [tch, setTch] = useState(true)
    const [search, setSearch] = useState('')

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data, request } = await myaxios.get(`ajax/role/${id}`)
            if (status === 200) {
                setRoleData(data)
                console.log({
                    ...data.Jsonfile,
                    ...data.permissions
                })
                setPermissions({
                    ...data.Jsonfile,
                    ...data.permissions
                })
            }

            if (request?.status === 404) history.push(`/settings/role`)
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [id])

    const updateRole = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.put(`ajax/role/${id}`, {
                permissions: permissions
            })
            if (status === 200) {
                toastifySuccess('Налаштування збережені')
                setTch(true)
            }
        } catch (e) {

        }
        setLoading(false)
    }

    const setRole = (index, type, event) => {
        const role = JSON.parse(JSON.stringify(permissions));
        role[index][type] = event;


        if(type === "view"){
            if(event === "denied"){
                role[index].update = "denied"
                if (role[index].delete) role[index].delete = "denied"
                if (role[index].export) role[index].export = "denied"
            } else if(event === "owned"){
                if (role[index].update !== "denied") role[index].update = "owned"
                if (role[index].delete && role[index].delete !== "denied") role[index].delete = "owned"
                if (role[index].export && role[index].export !== "denied") role[index].export = "owned"
            }
        }

        if(type === "update"){
            if(event === "denied"){
                role[index].delete = "denied"
            } else if(event === "owned"){
                if (role[index].delete !== "denied") role[index].delete = "owned"
            }
        }

        setPermissions((prevState) => ({ ...prevState, ...role }))
        setTch(false)
    }

    return loading || Object.keys(permissions).length < 1 ? <Loader /> : (
        <div>
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                <div style={{ fontFamily: 'Montserrat', fontWeight: 900, color: '#686868', textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
                    {roleData?.name}
                </div>
                <div className={'sbform flexDiv'} style={{ alignItems: 'center' }}>
                    <Input placeholder={'Пошук...'} value={search} onChange={e => setSearch((e.target.value || '').toLowerCase())} style={{ marginLeft: '10px' }} />
                    <button disabled={tch} onClick={updateRole} className="butt-group-card-wrap success fl-r btni ml10">Зберегти</button>
                </div>
            </div>
            <table className="table-prv" style={{ width: '100%' }}>
                <tr>
                    <td />
                    <td>Створення</td>
                    <td>Перегляд</td>
                    <td>Зміна</td>
                    <td>Видалення</td>
                    <td>Експорт</td>
                    <td>Звіти</td>
                </tr>
                {
                    Object.keys(permissions).filter(key => setLabel(key).name.toLowerCase().includes(search)).map((key, index) => {
                        if (setLabel(key).type === 'radio') {
                            return (
                                <tr key={key}>
                                    <td>{setLabel(key).name}</td>
                                    <td>
                                        {permissions[key].create ? <div className="checkedRole">
                                            <label className="checkedRoleAllowed"><input onClick={event => setRole(key,"create",event.target.value)} value="allowed" className="radio" type="radio" name={`create-${permissions[key].name}`} checked={(permissions[key].create === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            <label className="checkedRoleDenied"><input onClick={event => setRole(key,"create",event.target.value)} value="denied" className="radio" type="radio" name={`create-${permissions[key].name}`} checked={(permissions[key].create === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div>: null}
                                    </td>
                                    <td>
                                        {permissions[key].view ? <div className="checkedRole">
                                            <label className="checkedRoleAllowed"><input onClick={event => setRole(key,"view",event.target.value)} value="allowed" className="radio" type="radio" name={`view-${permissions[key].name}`} checked={(permissions[key].view === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            {permissions[key].owner ? <label className="checkedRoleOwned"><input onClick={event => setRole(key,"view",event.target.value)} value="owned" className="radio" type="radio" name={`view-${permissions[key].name}`} checked={(permissions[key].view === "owned")} />
                                                <span className="radio-custom" /></label> : null}
                                            <label className="checkedRoleDenied"><input onClick={event => setRole(key,"view",event.target.value)} value="denied" className="radio" type="radio" name={`view-${permissions[key].name}`} checked={(permissions[key].view === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div> : null}
                                    </td>
                                    <td>
                                        {permissions[key].update ? <div className="checkedRole">
                                            <label style={{ opacity: (permissions[key].view === "denied" || permissions[key].view === "owned") ? 0.2 : 1 }} className="checkedRoleAllowed"><input disabled={(permissions[key].view === "denied" || permissions[key].view === "owned")} onClick={event => setRole(key,"update",event.target.value)} value="allowed" className="radio" type="radio" name={`update-${permissions[key].name}`} checked={(permissions[key].update === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            {permissions[key].owner ? <label style={{ opacity: (permissions[key].view === "denied") ? 0.2 : 1 }} className="checkedRoleOwned"><input disabled={(permissions[key].view === "denied")} onClick={event => setRole(key,"update",event.target.value)} value="owned" className="radio" type="radio" name={`update-${permissions[key].name}`} checked={(permissions[key].update === "owned")} />
                                                <span className="radio-custom" /></label> : null}
                                            <label className="checkedRoleDenied"><input onClick={event => setRole(key,"update",event.target.value)} value="denied" className="radio" type="radio" name={`update-${permissions[key].name}`} checked={(permissions[key].update === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div> : null}
                                    </td>
                                    <td>
                                        {permissions[key].delete ? <div className="checkedRole">
                                            <label style={{ opacity: (permissions[key].update === "denied" || permissions[key].update === "owned") ? 0.2 : 1 }} className="checkedRoleAllowed"><input  disabled={(permissions[key].update === "denied" || permissions[key].update === "owned")} onClick={event => setRole(key,"delete",event.target.value)} value="allowed" className="radio" type="radio" name={`delete-${permissions[key].name}`} checked={(permissions[key].delete === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            {permissions[key].owner ? <label style={{ opacity: (permissions[key].update === "denied") ? 0.2 : 1 }} className="checkedRoleOwned"><input disabled={(permissions[key].update === "denied")} onClick={event => setRole(key,"delete",event.target.value)} value="owned" className="radio" type="radio" name={`delete-${permissions[key].name}`} checked={(permissions[key].delete === "owned")} />
                                                <span className="radio-custom" /></label> : null}
                                            <label className="checkedRoleDenied"><input onClick={event => setRole(key,"delete",event.target.value)} value="denied" className="radio" type="radio" name={`delete-${permissions[key].name}`} checked={(permissions[key].delete === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div> : null}
                                    </td>
                                    <td>
                                        {permissions[key].export ? <div className="checkedRole">
                                            <label style={{ opacity: (permissions[key].view === "denied" || permissions[key].view === "owned") ? 0.2 : 1 }} className="checkedRoleAllowed"><input disabled={(permissions[key].view === "denied" || permissions[key].view === "owned")} onClick={event => setRole(key,"export",event.target.value)} value="allowed" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key].export === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            {permissions[key].owner ? <label style={{ opacity: (permissions[key].view === "denied") ? 0.2 : 1 }} className="checkedRoleOwned"><input disabled={(permissions[key].view === "denied")} onClick={event => setRole(key,"export",event.target.value)} value="owned" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key].export === "owned")} />
                                                <span className="radio-custom" /></label> : null}
                                            <label className="checkedRoleDenied"><input onClick={event => setRole(key,"export",event.target.value)} value="denied" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key].export === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div> : null}
                                    </td>
                                    <td>
                                        {permissions[key].report ? <div className="checkedRole">
                                            <label className="checkedRoleAllowed"><input disabled={(permissions[key].view === "denied" || permissions[key].view === "owned")} onClick={event => setRole(key,"report",event.target.value)} value="allowed" className="radio" type="radio" name={`report-${permissions[key].name}`} checked={(permissions[key].report === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            {permissions[key].owner ? <label className="checkedRoleOwned"><input disabled={(permissions[key].view === "denied")} onClick={event => setRole(key,"report",event.target.value)} value="owned" className="radio" type="radio" name={`report-${permissions[key].name}`} checked={(permissions[key].report === "owned")} />
                                                <span className="radio-custom" /></label> : null}
                                            <label className="checkedRoleDenied"><input onClick={event => setRole(key,"report",event.target.value)} value="denied" className="radio" type="radio" name={`report-${permissions[key].name}`} checked={(permissions[key].report === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div> : null}
                                    </td>
                                    <td>
                                        <div className="checkedRole">
                                            <label style={{ textAlign:"right" }} className="checkedRoleAllowed"><input value="allowed" className="radio" type="radio" checked={(permissions[key].delete === "allowed")} />
                                                <span style={{ opacity:"0" }} className="radio-custom" />Дозволено</label>
                                            <label style={{ textAlign:"right" }} className="checkedRoleOwned"><input  value="owned" className="radio" type="radio" checked={(permissions[key].delete === "owned")} />
                                                <span style={{ opacity:"0" }} className="radio-custom" />Якщо відповідальний</label>
                                            <label style={{ textAlign:"right" }} className="checkedRoleDenied"><input value="denied" className="radio" type="radio" checked={(permissions[key].delete === "denied")} />
                                                <span style={{ opacity:"0" }} className="radio-custom" />Заборонено</label>
                                        </div>
                                    </td>
                                </tr>
                            )
                        } else if (setLabel(key).type === 'switch') {
                            return (
                                <tr>
                                    <td>{setLabel(key).name}</td>
                                    <td>
                                        {setLabel(key).key === 'view' && <div className="checkedRole">
                                            <label className="checkedRoleAllowed"><input onClick={event => {
                                                const dataPermissions = JSON.parse(JSON.stringify(permissions))
                                                dataPermissions[key][setLabel(key).key] = event.target.value
                                                setPermissions(dataPermissions)
                                                setTch(false)
                                            }} value="allowed" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key][setLabel(key).key || 'view'] === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            <label className="checkedRoleDenied"><input onClick={event => {
                                                const dataPermissions = JSON.parse(JSON.stringify(permissions))
                                                dataPermissions[key][setLabel(key).key] = event.target.value
                                                setPermissions(dataPermissions)
                                                setTch(false)
                                            }} value="denied" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key][setLabel(key).key || 'view'] === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div>}
                                    </td>
                                    <td />
                                    <td>
                                        {setLabel(key).key === 'update' && <div className="checkedRole">
                                            <label className="checkedRoleAllowed"><input onClick={event => {
                                                const dataPermissions = JSON.parse(JSON.stringify(permissions))
                                                dataPermissions[key][setLabel(key).key] = event.target.value
                                                setPermissions(dataPermissions)
                                                setTch(false)
                                            }} value="allowed" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key][setLabel(key).key || 'view'] === "allowed")} />
                                                <span className="radio-custom" /></label>
                                            <label className="checkedRoleDenied"><input onClick={event => {
                                                const dataPermissions = JSON.parse(JSON.stringify(permissions))
                                                dataPermissions[key][setLabel(key).key] = event.target.value
                                                setPermissions(dataPermissions)
                                                setTch(false)
                                            }} value="denied" className="radio" type="radio" name={`export-${permissions[key].name}`} checked={(permissions[key][setLabel(key).key || 'view'] === "denied")} />
                                                <span className="radio-custom" /></label>
                                        </div>}
                                    </td>
                                    <td /><td /><td />
                                    <td>
                                        <div className="checkedRole">
                                            <label style={{ textAlign:"right" }} className="checkedRoleAllowed"><input value="allowed" className="radio" type="radio" checked={(permissions[key].delete === "allowed")} />
                                                <span style={{ opacity:"0" }} className="radio-custom" />Дозволено</label>
                                            <label style={{ textAlign:"right" }} className="checkedRoleDenied"><input value="denied" className="radio" type="radio" checked={(permissions[key].delete === "denied")} />
                                                <span style={{ opacity:"0" }} className="radio-custom" />Заборонено</label>
                                        </div>
                                    </td>
                                </tr>
                            )
                        } else {
                            return <tr>
                                <td>{key}</td><td /><td /><td /><td /><td /><td />
                            </tr>
                        }
                    })
                }
            </table>
        </div>
    )
}