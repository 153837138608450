import {InfoTable} from "../../../components/cardViewComponents/InfoTable";
import {ContactFieldsData, ContactFieldsRender} from "../../../components/cardViewComponents/ContactFields";
import {GenerateForm} from "../../../components/cardViewComponents/GenerateForm";
import React, {useContext} from "react";
import MainEditor from "../../../components/UI/Editor";
import {contactConfig} from "../contactConfig";
import {Addresses} from "../../../components/cardViewComponents/Addresses";
import moment from "moment";

export const InfoTab = ({ id, values, errors, disabled, setValues, changeFields, saveItem, setContactFields }) => {
    const cost = 0
    const payment = 0

    return (
        <>
            <div className="tab-content sbform">
                <div className="connection" style={{ marginTop: 0 }}><span>Основна інформация</span></div>
                <InfoTable
                    arr={[
                        { value: 'Дата створення', label: moment(values.created_at).format("DD-MM-YYYY HH:mm") },
                        { value: 'Дата оновлення', label: moment(values.updated_at).format("DD-MM-YYYY HH:mm") },
                        /*{ value: 'Сумма оплат', label: `${payment && payment.toFixed(2)}` },
                        { value: 'Сверка по счетам', render: () => <>
                                {((payment - cost) < 0) ? <b style={{color: "#c2263f"}}>Задолженность:</b> : <b style={{color: "#62a091"}}>Задолженности нет:</b>} {(payment - cost).toFixed(2)} {baseCurrency ? baseCurrency.text_symbol : null}
                            </> },
                        { value: 'RFM сегменты', render: () => <>
                                {(values.rfmSegments || []).map(item => item && <span className="label-st" style={{ backgroundColor: item.segment && item.segment.color }} key={item.segment_id}>{item.segment && item.segment.name}</span>)}
                            </> },
                        { value: 'Сегменты', render: () => <>
                                {(values.segments || []).map(item => item && <span className="label-st" style={{ color: "#2b2b2b" }} key={item.segment_id}>{item.segment && item.segment.name}</span>)}
                            </> },*/
                    ]}
                />
                <div className="connection"><span>Контактна інформация</span></div>
                <GenerateForm
                    config={{ ...contactConfig, ...values.extra_fields }}
                    { ...{ values, errors, disabled } }
                    onChange={changeFields}
                    onSaveData={saveItem}
                    zone={2}
                />
                {/*<ContactFieldsRender
                    errors={errors}
                    contactFields={values.contactFields}
                    setValues={setContactFields}
                />*/}
                {/*<Addresses contact_id={id} entity={'contact'} />*/}
                <div className="connection"><span>Додаткова інформация</span></div>
                <div className="clear" />
                <div className="fgroup rc12">
                    <MainEditor
                        label={'Опис'}
                        value={values.description}
                        height={350}
                        valid={!errors.description}
                        errorMessage={errors.description}
                        onChange={description => {
                            if(description !== values.description) {
                                setValues({ description })
                            }
                        }}
                    />
                </div>

            </div>
        </>
    )
}