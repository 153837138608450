import React from "react";
//import {ContactModal} from "../../containers/contacts/ContactModal";
import {CreateRole} from "../../Modal/CreateRole";
import {CreateUser} from "../../Modal/CreateUser";
import {CreateShop} from "../../containers/settings/routes/shop/CreateShop";
import {ContactModal} from "../../containers/contacts/ContactModal";
// import {TaskModal} from "../../containers/tasks/TaskModal";
// import {AccountModal} from "../../containers/accounts/AccountModal";
// import {ProductModal} from "../../containers/product/ProductModal";
// import {OpportunityModal} from "../../containers/opportunity/OpportunityModal";
// import {CreateInvoice} from "../../containers/invoice/CreateInvoice";
// import {OrderModal} from "../../containers/order/OrderModal";
// import {LeadModal} from "../../containers/lead/LeadModal";
// import {DocumentModal} from "../../containers/document/DocumentModal";
// import {DocumentTemplateModal} from "../../containers/settings/routes/documentTemplate/DocumentTemplateModal";
// import {ReplanishModal} from "../../containers/stock/replenish/ReplanishModal";
// import {ShipmentModal} from "../../containers/stock/shipment/ShipmentModal";
// import {TransferModal} from "../../containers/stock/transfer/TransferModal";
// import {ConsumptionModal} from "../../containers/stock/consumption/ConsumptionModal";
// import {OrderReturnModal} from "../../containers/stock/orderReturn/returnModal";
// import {PurchaseModal} from "../../containers/stock/purchase/PurchaseModal";

export const ModalConfig = [
    /*{ name: 'lead', Component: LeadModal },
    { name: 'account', Component: AccountModal },
    { name: 'product', Component: ProductModal },
    { name: 'opportunity', Component: OpportunityModal },
    { name: 'document', Component: DocumentModal },*/
    { name: 'contact', Component: ContactModal },
    { name: 'role', Component: CreateRole },
    { name: 'user', Component: CreateUser },
    { name: 'shop', Component: CreateShop },
    /*{ name: 'task', Component: (props) => <TaskModal {...props} id={props && props.data && props.data.id} related={props.data && props.data.related} />},
    { name: 'invoice', Component: CreateInvoice },
    { name: 'order', Component: OrderModal },
    { name: 'document-template', Component: DocumentTemplateModal },
    { name: 'document-template', Component: DocumentTemplateModal },
    { name: 'replenish', Component: ReplanishModal },
    { name: 'shipment', Component: ShipmentModal },
    { name: 'transfer', Component: TransferModal },
    { name: 'consumption', Component: ConsumptionModal },
    { name: 'order_return', Component: OrderReturnModal },
    { name: 'purchase', Component: PurchaseModal },*/
]