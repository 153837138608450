import React, {useState} from "react";
import Input from "../../components/UI/Input";
import {myaxios} from "../../services/axios";
import {validationData} from "../../components/UI/formValid";
import logo from '../../accets/images/logo-crm.png'
import './login.css'
import {toastifyWarning} from "../../components/toastify/toastify";
import Loader from "../../components/Loader";

export const Login = () => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const validationForm = async () => {
        const errors = validationData(values, {
            email: { required: true, email: true, maxLength: 255 },
            password: { required: true, minLength: 6, maxLength: 255 }
        })

        if (Object.keys(errors).length > 0) {
            toastifyWarning('Помилка валідації')
            setErrors(errors)
            return false
        } else setErrors({})

        await auth()
    }

    const auth = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`ajax/user/login`, {
                email: values.email,
                password: values.password
            })

            if (status === 200 && data?.token) {
                localStorage.setItem('accessToken', data.token)
                window.location.reload()
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return (
        <div className={'login-page'}>
            {loading && <Loader />}
            <div className="login-page__container">
                <img className={'login-page__logo'} src={logo} alt=""/>
                <Input
                    otherProps={{
                        placeholder: 'Email',
                        value: values.email,
                        onChange: event => setValues({ ...values, email: event.target.value }),
                        onKeyDown: event => event.keyCode === 13 && validationForm()
                    }}
                    errorMessage={errors?.email} valid={!errors.email} shouldValidate
                />
                <Input
                    otherProps={{
                        placeholder: 'Пароль',
                        type: 'password',
                        value: values.password,
                        onChange: event => setValues({ ...values, password: event.target.value }),
                        onKeyDown: event => event.keyCode === 13 && validationForm()
                    }}
                    errorMessage={errors?.password} valid={!errors.password} shouldValidate
                />
                <button onClick={validationForm}>Увійти</button>
            </div>
        </div>
    )
}