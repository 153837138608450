import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../components/NewList/NewList";

export const ListContactNew = (props) => {
    return (
        <NewList
            { ...props }
            entity={'contact'}
            entity_type={2}
            requiredFields={'id,name,type_id,work_phone,phone,email,position,created_at,owner_id'}
            requiredExpand={'Type,account,Owner'}
            deleteToShowColumn={'id,name,type_id,work_phone,phone,email,first_name,last_name,middle_name,account_id,position,description'}
            additionalFieldsExists={false}
            isFilterExists={false}
            filterFields={entity.contact.fArr}
            singleSearch={entity.contact.fArr[0]}
            headingColumns={['ПІБ', 'Статус', 'Пошта', 'Телефони']}
            isPagination
            //analytics={'/order/report/'}
            RenderColumn={(item) => (
                <>
                    <td>
                        <div>
                            <NavLink to={`/contact/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                        </div>
                        <div>
                            {item?.position}{item?.account ? <span>{` у компанії`} <NavLink to={`/account/${item?.account?.id}`}>{item?.account?.name}</NavLink></span> : ''}
                        </div>
                    </td>
                    <td>
                        {item.type && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.type?.name}</span>}
                    </td>
                    <td>
                        <div>{item.email}</div>
                    </td>
                    <td>
                        <div>{item.phone}</div>
                        <div>{item.work_phone}</div>
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('contact')} className="open-modal-button btni">Додати контакт</button>}
            rightNavbarConfig={{
                export: true,
                additionalFields: '/extra-fields/contact',
                columns: true,
                import: '/import/contact'
            }}
            massDoConfig={{ deleteAll: `/ajax/contact/delete-all` }}
            contextNav={{ edit: true, task: true, print: false, delete: true }}
            sort={entity.contact.fArr.filter(el => el.sort)}
            extra_fields_table_name={'contact_extra_fields'}
        />
    )
}