import { toast, ToastOptions } from 'react-toastify'

const toastConfig = {
  position: 'top-left',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const toastifySuccess = (text) => {
  toast.success(text, { ...toastConfig })
}

export const toastifyError = (text) => {
  toast.error(text, { ...toastConfig })
}

export const toastifyWarning = (text) => {
  toast.warning(text, { ...toastConfig })
}
