import React, {useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";
import Input from "../UI/Input";
import {myaxios} from "../../services/axios";

export const TagsNew = ({ entity, editorTags, setValues }) => {
    const [tags, setTags] = useState([])
    const [value, setValue] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const getAllTags = async () => {
        try {
            const { status, data } = await myaxios.get(`ajax/tags?filter[entity][like]=${entity}&order=id`)
            if (status === 200) {
                setTags(data.items)
            }
        } catch (e) {}
    }

    useEffect(() => {
        if (isOpen && tags.length === 0) {
            getAllTags().then()
        }
    }, [isOpen])

    const text = `Теги ${((editorTags.length > 0) ? `(${editorTags.length})` : '(0)')}`
    return (
        <div className="tagsName" ref={dropdownRef}>
            <a
                onClick={() => setIsOpen(!isOpen)} className="success fl-l btni mr5" >
                {isOpen ? 'Закрити' : text}
            </a>
            <CSSTransition in={isOpen} timeout={200} classNames="slide" unmountOnExit>
                <div className="tagsName__dropdown sbform">
                    <Input
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={e => {
                            if (e.keyCode === 13 && value.trim().length >= 1 && !tags.find(el => el.name === value)) {
                                setTags([...tags, { id: null, entity, name: value }])
                                setValues([ ...editorTags, { id: null, entity, name: value } ])
                                setValue('')
                            }
                        }}
                    />
                    <div className="bp-tags-items" style={{ marginTop: '5px' }}>
                        {
                            tags.filter(el => el.name.toLowerCase().includes(value.toLowerCase())).map(item => <div
                                onClick={() => {
                                    if (editorTags.find(el => el.name === item.name)) setValues(editorTags.filter(el => el.name !== item.name))
                                    else setValues([ ...editorTags, item ])
                                }}
                                className={`bp-tags-item ${editorTags.find(el => el.name === item.name) && 'bp-tags-item-active'}`}>
                                {item.name}
                            </div>)
                        }
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}