import {useEffect, useState} from "react";
import {myaxios} from "../../../../services/axios";
import {toastifySuccess} from "../../../../components/toastify/toastify";
import {CreateCurrency} from "./CreateCurrency";
import Myselect from "../../../../components/UI/Select";

export const CompanySettings = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currencies, setCurrencies] = useState([])
    const [initialBaseCurrency, setInitialBaseCurrency] = useState('')
    const [baseCurrency, setBaseCurrency] = useState('')

    const getCurrenciesData = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`ajax/currency?per-page=100&order=created_at`)
            if (status === 200) {
                setCurrencies(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    /*const getBaseCurrency = async () => {
        try {
            const { status, data } = await myaxios.get(`ajax/company-profile/1`)
            if (status === 200) {
                setInitialBaseCurrency(data.value)
                setBaseCurrency(data.value)
            }
        } catch (e) {}
    }*/

    /*const saveBaseCurrency = async () => {
        try {
            const { status, data } = await myaxios.put(`ajax/company-profile/1`, { value: baseCurrency })
            if (status === 200) {
                toastifySuccess('Налаштування збережені')
                setInitialBaseCurrency(baseCurrency)
            }
        } catch (e) {}
    }*/

    useEffect(() => {
        getCurrenciesData().then()
        //getBaseCurrency().then()
    }, [])

    const deleteItem = async (id) => {
        if (!window.confirm('Ви впевнені?')) return

        try {
            const { status, data } = await myaxios.delete(`ajax/currency/${id}`)
            if (status === 204) {
                await getCurrenciesData()
                toastifySuccess('Запис успішно видалений')
            }
        } catch (e) {}
    }

    return <div>
        <div className={'second-table list-settings-new'}>
            {/*<div className={'sbform mb20'} style={{ display: "flex", alignItems: 'flex-end' }}>
                <Myselect
                    style={{ width: '250px' }}
                    label={'Базова валюта'}
                    value={baseCurrency}
                    options={currencies} val={'id'} lab={'name'}
                    onChange={e => setBaseCurrency(e.target.value)}
                />
                {String(baseCurrency) !== String(initialBaseCurrency) && <button onClick={saveBaseCurrency} className={'success btni ml10'}>Зберегти</button>}
            </div>*/}

            <h2>Валюти</h2>
            <table className={'w100'}>
                <tr>
                    <th>Назва</th>
                    <th>Char code</th>
                    <th>Num code</th>
                    <th>Символ</th>
                    <th>Текстовий символ</th>
                    <th>Курс (відносно базової валюти)</th>
                    <th />
                    <th />
                </tr>
                {
                    (currencies || []).map(item => (
                        <tr>
                            <td>{item.name}</td>
                            <td>{item.char_code}</td>
                            <td>{item.num_code}</td>
                            <td>{item.symbol}</td>
                            <td>{item.text_symbol}</td>
                            <td>{item.value}</td>
                            <td>
                                <a onClick={() => { setModalData(item.id); setIsOpen(true) }} className="second-table__icon second-table__icon-grey">&#9998;</a>
                            </td>
                            <td>
                                <a onClick={() => deleteItem(item.id)} className="second-table__icon second-table__icon-red">✕</a>
                            </td>
                        </tr>
                    ))
                }
            </table>
            <button onClick={() => setIsOpen(true)} className={'success btni mt10'}>Додати валюту</button>
        </div>
        {
            <CreateCurrency
                isOpen={isOpen}
                id={modalData}
                refresh={() => {
                    getCurrenciesData().then()
                    setIsOpen(false)
                    setModalData(null)
                }}
                close={() => {
                    setIsOpen(false)
                    setModalData(null)
                }}
            />
        }
    </div>
}