export const formatDateTime = (parsedDate) => {
    const date = new Date(parsedDate)
    if(date) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        let hh = date.getHours();
        let m = date.getMinutes();
        let ss = date.getSeconds();
        return yy + '.' + mm + '.' + dd + ' ' + hh + ':' + m + ':' + ss;
    }
}

export function formatDate(parsedDate){
    const date = new Date(parsedDate)
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return yy + '-' + mm + '-' + dd;
    }
}
