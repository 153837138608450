import {useEffect, useState} from "react";
import {GenerateForm} from "../../../../components/cardViewComponents/GenerateForm";
import Modal from "../../../../components/Modal/Modal";
import {validationData} from "../../../../components/UI/formValid";
import {validationConfig} from "../../../../components/cardViewComponents/validationConfig";
import {toastifyWarning} from "../../../../components/toastify/toastify";
import {myaxios} from "../../../../services/axios";
import {parseBackValidationErrors} from "../../../../helpers/parseBackValidationErrors";

const fieldsConfig = {
    name: {
        label: 'Назва',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 20 }, zone: 1
    },
    char_code: {
        label: 'Сhar code',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 3 }, zone: 1
    },
    num_code: {
        label: 'Num code',
        type: 'number',
        className: 'w100',
        validation: { required: true, number: true }, zone: 1
    },
    symbol: {
        label: 'Символ',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 3 }, zone: 1
    },
    text_symbol: {
        label: 'Текстовий символ',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 10 }, zone: 1
    },
    value: {
        label: 'Курс (відносно базової валюти)',
        type: 'number',
        className: 'w100',
        validation: { required: true }, zone: 1
    },
}

const initialValues = {
    name: '',
    char_code: '',
    num_code: 0,
    symbol: '',
    text_symbol: '',
    value: null,
    tch: true
}

export const CreateCurrency = ({ isOpen, close, refresh, id }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState(initialValues)

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`ajax/currency/${id}`)
            if (status === 200) {
                setValues({
                    ...values,
                    ...data
                })
            }
        } catch (e) {
            
        }
        setLoading(false)
    }

    useEffect(() => {
        if (isOpen && id) {
            getData().then()
        } else {
            setValues(JSON.parse(JSON.stringify(initialValues)))
        }
    }, [isOpen])

    const saveItem = async () => {
        const errorsData = validationData(values, validationConfig(fieldsConfig));
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            toastifyWarning('Помилка валідації')
            return false;
        }
        setErrors({})

        const { name, char_code, num_code, symbol, text_symbol, value } = values
        const sendData = {
            name,
            char_code,
            num_code: +num_code,
            symbol,
            text_symbol,
            value: +value,
            order: 4,
            nominal: 1
        }

        setLoading(true)
        try {
            const { status, data, request } = id ? await myaxios.put(`ajax/currency/${id}`, sendData) : await myaxios.post(`ajax/currency`, sendData)
            if (status === 200 || status === 201) {
                refresh()
            } else if (request?.status === 422) {
                const res = JSON.parse(request.response)
                if (res.errors) setErrors(parseBackValidationErrors(res.errors))
            }
        } catch (e) {}
        setLoading(false)
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, tch: false })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Валюта'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}