import React, {useEffect, useState, useRef, useContext} from "react";
import Loader, {ModalLoader} from "../UI/Loader";
import {ListFilter} from "./ListFilter";
import {entity} from "../ListComponentEntity";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {ListColumnSettings} from "./components/ListColumnSettings";
import {Pagination} from "./components/Pagination";
import {buildExpandLink} from "./components/ExtraColumnsRender";
import {ListContextMenu} from "./components/ListContextMenu";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";
import {TableHead} from "./components/TableHead";
import {TableBody} from "./components/TableBody";
import {convertFilter} from "./components/convertFilter";
import {ModalListContainer} from "./components/ModalListContainer";
// import StockNav from "../../containers/stock/StockNav";
import {CanDelete} from "../../Modal/CanDelete";
import {toastifyError, toastifySuccess} from "../toastify/toastify";
import {myaxios} from "../../services/axios";
import {ContextProvider} from "../../Context";
import {ExportModal} from "./components/ExportModal";
// import StockNav from "../../containers/stock/StockNav";



const MassDo = ({ deleteAll, tags, onDeleteAll }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return (
        <div style={{ position: 'relative', marginLeft: '5px' }} ref={dropdownRef}>
            <a onClick={() => setIsOpen(!isOpen)} className="open-modal-button btni">Дії</a>
            <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
                <div className="toogle-list my-node-enter-done" style={{ display: 'block', top: '35px', left: 0 }}>
                    {deleteAll && <li style={{ listStyle: 'none' }}><a onClick={onDeleteAll}><b>Видалити елементи</b></a></li>}
                    {tags && <li style={{ listStyle: 'none' }}><a><b>Додати теги</b></a></li>}
                </div>
            </CSSTransition>
        </div>
    )
}

export const NewList = ({
    entity,
    entity_type,
    requiredFields,
    requiredExpand,
    filterFields,
    additionalFieldsExists = false,
    isFilterExists = false,
    userId,
    RenderColumn,
    headingColumns = [],
    deleteToShowColumn = '',
    rightNavbarConfig,
    updateData,
    massDoConfig,
    contextNav,
    singleSearch,
    sort,
    analytics,
    createButtonRender,
    onCreatedResult,
    stockNav,
    topNav,
    dropdownRender,
    updatedNumber,
    hidePerPage,
    updateOutElements = () => {},
    extra_fields_table_name,
    custom_filter_link,
    doubleClickLink,
    isDoubleClick = true,
    isPagination
}) => {
    const { user } = useContext(ContextProvider)
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [isColumnsOpen, setIsColumnsOpen] = useState(false)
    const [filters, setFilters] = useState([])
    const [columns, setColumns] = useState([])
    const [sortActive, setSortActive] = useState('-created_at')
    const [filterId, setFilterId] = useState('')
    const [additionalFields, setAdditionalFields] = useState([])
    const [contextMenu, setContextMenu] = useState(null)
    const [checkItems, setCheckItems] = useState([])
    const [activeCheckItems, setActiveCheckItems] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const [exportModal, setExportModal] = useState(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const history = useHistory()
    const location = useLocation()
    const query = Object.fromEntries(urlSearchParams.entries());
    const perPage = localStorage.getItem(entity + 'PerPage') || 20;

    const getExtraFields = async () => {
        if (!additionalFieldsExists) {
            return
        }
        try {
            const { status, data } = await myaxios.get(`ajax/extra-fields?filter[entity][eq]=${entity}&expand=options&fields=created_at,id,label,field_type`)
            if (status === 200) setAdditionalFields(data.items.map(el => ({ ...el, value: `f_${el.id}`, filterField: "autoselect", fieldType: el.field_type })))
        } catch (e) {}
    }

    const getData = async () => {
        setLoading(true)

        let sortOrder = ''
        let resColumns = ''
        let resColumnsData = ''
        let extra_expand = ''

        try {
            const filterData = isFilterExists ? await myaxios.get(`/ajax/filter?filter[entity][eq]=${entity}&[user_id][eq]=${user?.id}`) : null
            let filterLink = '';

            if(isFilterExists && filterData.data.items && filterData.data.items[0]) {
                setFilters(filterData.data.items[0].filterItems || [])
                if(filterData.data.items[0]){
                    filterLink = (convertFilter(filterData.data.items[0].filterItems || []))
                    setFilterId(filterData.data.items[0].id)
                    setColumns(filterData.data.items[0].columns.split(',') || [])
                    setSortActive(filterData.data.items[0].order || '-created_at')
                }
                extra_expand = filterData.data.items[0].columns.includes('f_') ? ',extra_fields' : ''

                sortOrder = (filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].order) || '-created_at'
                resColumns = filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].columns.split(',')
                resColumnsData = resColumns.length > 0 ? `,${resColumns.join(',')}` : ''
            }

            if (query.query) {
                filterLink = window.location.search.replace('?query=true', '')
            }

            const { data, status } =
                await myaxios.get(`/ajax/${entity}?order=${sortOrder}${custom_filter_link || ''}&per-page=${perPage}&expand=${requiredExpand + extra_expand}${buildExpandLink(resColumnsData)}&fields=${requiredFields}${resColumnsData}&page=${query.page || 1}${query.direct ? window.location.search.substr(9) : filterLink}`);
            if(status === 200) {
                if(additionalFieldsExists && data.attributes) {
                    setAdditionalFields(data.attributes.map(el => ({
                        value: el.field_id,
                        label: el.label,
                        fieldType: (el.type === "select" || el.type === "multiselect" || el.type === 'multiple') ? 'select' : (el.type === "date") ? 'date' : 'text',
                        options: el.options
                    })))
                }

                isPagination && getCount().then()
                setList(data.items)
                //setTotalCount(+data._meta.totalCount)
                updateOutElements()
            }
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    const getCount = async () => {
      const { data, status } = await myaxios.get(`/ajax/${entity}/count`)
        if (status === 200) {
            setTotalCount(data)
        }
    }

    useEffect(() => {
        (async () => {
            await getData()
            getExtraFields().then()
        })()
    }, [query.page, updatedNumber])

    const deleteSingleElement = async (id) => {
        if(!window.confirm('Удалить элемент?')) return;
        setLoading(true)

        const { data, status } = await myaxios.delete(`/ajax/${entity}/${id}`)
        if(status === 200 || status === 204) {
            if(!data.canDelete) {
                setContextMenu(null)
                await getData()
            } else if(data.canDelete && data.items) {
                setModalData({ type: 'canDelete', items: data.items })
            } else {
                setContextMenu(null)
                toastifyError('При видаленні щось пішло не так')
            }
        }

        setLoading(false)
    }

    const saveFilter = async (params = [], clearId, editedColumns, editedSort) => {
        setLoading(true)

        const postData = {
            name: entity,
            entity_type: entity,
            user_id: user?.id,
            filter_attributes: clearId ? filters.filter(el => el.id != clearId) : [...filters, ...params],
            columns: editedColumns || columns,
            order: editedSort || sortActive
        }

        const { status, data } = filterId ?
            await myaxios.put(`/ajax/filter/${filterId}`, postData) : await myaxios.post(`/ajax/filter`, postData)

        if(status === 200 || status === 201) {
            setIsColumnsOpen(false)
            toastifySuccess('Дані фільтра збережені')
            await getData()
        }

        setLoading(false)
    };

    const deleteAll = async () => {
        if(!window.confirm('Видалити елементи?')) return;

        setLoading(true)
        /*try {
            const { status, data } = await myaxios.post(massDoConfig.deleteAll, { ids: checkItems })
            if(status === 200 && data) {
                if(data.deleted && data.deleted.length > 0) {
                    toastifySuccess('delete', `Удалено ${data.deleted.length} записей`)
                    setCheckItems(checkItems.filter(el => !data.deleted.includes(el)))
                }
                if(data.not_deleted && data.not_deleted.length > 0) {
                    Notif('Error', `Не удалено ${data.not_deleted.length} записей`)
                }
                await getData()
            }
        } catch (e) {

        }*/

        setLoading(false)
    }

    const checkElement = (id) => {
        setActiveCheckItems(true)
        !checkItems.includes(id) ?
            setCheckItems(prevState => ([...prevState, id])) :
            setCheckItems(prevState => ( prevState.filter(el => el !== id) ))
    }

    const setPerPage = (perPage) => {
        localStorage.setItem(entity + 'PerPage', perPage)
        setTimeout(() => window.location.reload(), 200)
    }

    const deleteFilterItem = async (id) => {
        try {
            const { status } = await myaxios.delete(`ajax/filter/filter-item/${id}`)
            if (status === 204) {
                await getData()
            }
        } catch (e) {

        }
    }

    return (
        <div className={'page'}>
            {/*{stockNav && <StockNav />}*/}
            {topNav && topNav()}
            {<CSSTransition in={loading} timeout={100} classNames="my-node" unmountOnExit>
                <ModalLoader />
            </CSSTransition>}
            <ListFilter
                updateData={(type) => {
                    if (type === 'Export') setExportModal(true)
                }}
                { ...{ saveFilter, filters, rightNavbarConfig, setIsColumnsOpen, singleSearch, entity, sort, sortActive, isFilterExists, deleteFilterItem }}
                filterFields={[
                    ...filterFields,
                    ...additionalFields.map(el => {
                        if (el.field_type === 'url') {
                            return { ...el, field_type: 'text', fieldType: 'text' }
                        } else if (el.field_type === 'list' || el.field_type === 'multiple') {
                            return { ...el, field_type: 'select', fieldType: 'select', options: (el.options || []).map(option => ({ ...option, value: option.id, label: option.name })) }
                        } else if (el.field_type === 'time') {
                            return {}
                        } else {
                            return el
                        }
                    }).filter(el => el.fieldType)
                ]}
                LeftRender={() => (
                    <>
                        {analytics && <NavLink to={analytics} data-tooltip="Отчеты" className="xsn btns"><i className="flaticon-diagram" /></NavLink>}
                        {createButtonRender && createButtonRender(setModalType)}
                        {!!massDoConfig && checkItems.length > 0 && <MassDo {...massDoConfig} onDeleteAll={deleteAll} />}
                    </>
                )}
                dropdownRender={dropdownRender}
                perPage={!hidePerPage && (localStorage.getItem(entity + 'PerPage') || 20)}
                setPerPage={setPerPage}
            />
            <div className="contact-page sp-table scrollbar" style={{ marginTop: 0 }}>
                <table style={{ borderSpacing: 0 }}>
                    {list.length > 0 && <TableHead {...{
                            massDoConfig,
                            activeCheckItems,
                            checkItems,
                            setCheckItems,
                            headingColumns,
                            list,
                            columns,
                            filterFields,
                            additionalFields
                        }}
                    />}
                    {
                        list.length === 0 && <div className={'list-is-empty'}>Тут поки що нічого немає</div>
                    }
                    <TableBody
                        {...{
                            list,
                            history,
                            entity,
                            checkElement,
                            contextMenu,
                            setContextMenu,
                            massDoConfig,
                            activeCheckItems,
                            checkItems,
                            columns,
                            filterFields,
                            additionalFields,
                            RenderColumn,
                            setModalType,
                            setModalData,
                            extra_fields_table_name,
                            doubleClickLink,
                            isDoubleClick
                        }}
                    />
                </table>
            </div>
            {list.length > 0 && (Math.ceil(totalCount / perPage) > 1) && <Pagination
                pagesAmount={Math.ceil(totalCount / perPage )}
                currentPage={+query.page || 1}
                setCurrentPage={page => {
                    history.push(`${location.pathname}?page=${page}`)
                    setCheckItems([])
                }}
            />}
            <ListColumnSettings
                {...{ columns, saveFilter, deleteToShowColumn, loading }}
                isOpen={isColumnsOpen}
                close={() => setIsColumnsOpen(false)}
                filterFields={[ ...filterFields, ...additionalFields ]}
            />
            {contextNav && <ListContextMenu
                contextMenu={contextMenu}
                close={() => setContextMenu(null)}
            >
                <>
                    {!!massDoConfig && <li onClick={() => { setContextMenu(null); checkElement(contextMenu.id); }} className={'listContext-delete'}>Вибрати (CTRL + клік)</li>}
                    {contextNav.edit && <li onClick={() => history.push(`/${entity}/${contextMenu.id}`)} className={'listContext-delete'}>Редагувати (double click)</li>}
                    {contextNav.task && <li onClick={() => { setModalType('task'); setContextMenu(null); setModalData({ related: { id: contextMenu.id, type: entity_type } }) }} className={'listContext-delete'}>Додати завдання</li>}
                    {contextNav.print && <li onClick={() => { setContextMenu(null); setModalType('print'); setModalData({ id: contextMenu.id }) }} className={'listContext-delete'}>Друк</li>}
                    {contextNav.clone && <li onClick={() => history.push(`/${entity}/${contextMenu.id}`)} className={'listContext-delete'}>Клонувати</li>}
                    {contextNav.delete && <li onClick={() => deleteSingleElement(contextMenu.id)} className={'listContext-delete'}>Видалити</li>}
                </>
            </ListContextMenu>}
            <ModalListContainer
                {...{ entity, entity_type, history }}
                type={modalType}
                data={modalData}
                close={() => { setModalType(''); setModalData(null) }}
                onUpdateResult={onCreatedResult === 'update-list' && getData}
            />
            <CanDelete
                isOpen={modalData && modalData.type === 'canDelete'}
                close={() => setModalData(null)}
                items={modalData && modalData.items}
                entity={entity} delParent={deleteSingleElement}
                name={'запис'}
                id={contextMenu && contextMenu.id} history={history}
            />
            {exportModal &&
                <ExportModal
                    options={filterFields}
                    additionalFields={additionalFields}
                    filters={filters}
                    entity={entity}
                    close={() => setExportModal(false)}
                />
            }
        </div>
    )
}