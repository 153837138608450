import Input from "../../../UI/Input";
import {useEffect, useRef, useState} from "react";
import {formatDate} from "../../../cardViewComponents/formatDate";
import {toastifyWarning} from "../../../toastify/toastify";

export const FilterRangeNumberInput = ({ onChange, value }) => {
    useEffect(() => {
        onChange(`0/0`)
    }, [])

    return (
        <div style={{ display: 'flex' }}>
            <Input
                className={'w50'}
                label={'А'}
                type={'number'}
                value={value.split('/')[0]}
                onChange={e => {
                    onChange(`${e.target.value}/${value.split('/')[1]}`)
                }}
            />
            <Input
                className={'w50'}
                label={'Б'}
                type={'number'}
                value={value.split('/')[1]}
                onChange={e => {
                    onChange(`${value.split('/')[0]}/${e.target.value}`)
                }}
            />
        </div>
    )
}