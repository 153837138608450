import React, {useEffect, useState} from "react";
import {DDlist} from "./DDlist";
import Modal from "../Modal";
import {myaxios} from "../../services/axios";

export const lookupItemConfig = (type, item) => {
    switch (type) {
        case 'source': return {
            title: 'Джерела заявок',
            config: {
                secondField: { value: 'value', label: 'Значення utm_source', type: 'text', validation: { required: true } }
            }
        }
        case 'tsk_type': return {
            title: 'Тип задачі',
            config: {}
        }
        case 'tsk_status': return {
            title: 'Статус задачі',
            config: {}
        }
        case 'acc_type': return {
            title: 'Тип контрагентів',
            config: {}
        }
        case 'ct_type': return {
            title: 'Тип контактів',
            config: {}
        }
        case 'case_status': return {
            title: 'Статус звернення',
            config: {}
        }
        case 'case_type': return {
            title: 'Тип звернення',
            config: {}
        }
        case 'case_source': return {
            title: 'Джерело звернення',
            config: {}
        }
        case 'dt_status': return {
            title: 'Статус документа',
            config: {}
        }
        case 'dt_type': return {
            title: 'Тип документа',
            config: {}
        }
        case 'exp_category': return {
            title: 'Категорії витрат',
            config: {}
        }
        case 'payment_category': return {
            title: 'Категорії доходів',
            config: {}
        }
        case 'payment_method': return {
            title: 'Методи оплати',
            config: {}
        }
        case 'inv_prefix': return {
            title: 'Префікс для рахунків',
            config: {
                hideColor: true,
                secondField: { value: 'value', label: 'Номер лічильника' }
            },
            field: 'name',
        }
        case 'ld_status': return {
            title: 'Статус ліда',
            config: {}
        }
        case 'inv_status': return  {
            title: 'Статуси рахунків',
            config: { hideColor: true }
        }
        case 'add_way': return {
            title: 'Як доданий',
            config: {}
        }
        case 'opp_stage': return {
            title: 'ЕТАПИ УГОД',
            config: {}
        }
        case 'opp_type': return {
            title: 'ТИПИ УГОД',
            config: {}
        }
        case 'flr_reason': return {
            title: 'ТИПИ УГОД',
            config: {}
        }
        case 'ord_payment_type': return {
            title: 'Тип оплати',
            config: {}
        }
        case 'ord_delivery_type': return {
            title: 'Тип доставки',
            config: {}
        }
        case 'ord_status': return {
            title: 'Статуси замовлень',
            config: { hideColor: true }
        }
        case 'tax': return {
            title: 'Податок',
            config: {},
            field: 'name',
            secondField: { value: 'value', label: 'Значение %', type: 'number', validation: { number: true } }
        }
        case 'prd_attr': return {
            title: 'Атрибути продукту',
            config: {},
            field: 'name',
            secondField: { hideColor: true }
        }
        case 'unit': return {
            title: 'Одиниця виміру',
            config: {}
        }
        case 'ord_item_status': return {
            title: 'Статус продукту',
            config: {}
        }
        case 'lead_type': return {
            title: 'Тип потреби',
            config: {}
        }
        case 'cn_status': return {
            title: 'Статуси списань',
            config: {}
        }
        case 'prcs_status': return {
            title: 'Статуси закупівлі',
            config: {}
        }
        case 'rsh_status': return {
            title: 'Статусы прихода',
            config: {}
        }
        case 'sht_status': return {
            title: 'Статуси відвантаження',
            config: {}
        }
        case 'tr_status': return {
            title: 'Статуси переміщення',
            config: {}
        }
        case 'stk_status': return {
            title: 'Статуси інвентаризації',
            config: {}
        }
        case 'purchase_status': return {
            title: 'Статуси закупівлі',
            config: {}
        }
        case 'lead_failure_reason': return {
            title: 'Причини скасування ліда',
            config: {}
        }
        case 'opp_failure_reason': return {
            title: 'Причини скасування угоди',
            config: {}
        }

        default: return {
            title: 'Unknown', config: { hideColor: true, hideOrder: true }
        }
    }
}

function compare( a, b ) {
    if ( a.index < b.index ){
        return -1;
    }
    if ( a.index > b.index ){
        return 1;
    }
    return 0;
}

export const ListSettingsModal = ({ isOpen, type, close = () => {}, save = () => {} }) => {
    const [loading, setLoading] = useState(true)
    const [list,setList]= useState([])

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`/ajax/lookup?type=${type}&order=order`)
            if(status === 200 && data) {
                setList(data.items.map((item, index) => ({ index, ...item })))
            }
        } catch (e) {}
        setLoading(false)
    }

    const putData = async () => {
        setLoading(true)

        const newElements = list.filter(el => !el.id)
        const sortedElements = list.filter(el => el.id).sort(compare)
        const result = [...sortedElements, ...newElements]

        try {
            const { status, data } = await myaxios.post(`/ajax/lookup`, result.filter(el => el.name.trim().length > 0).map(item => ({
                id: item.id,
                color: item.color,
                type: type,
                name: item.name,
                order: item.order,
                entity: type,
                value: item.value
            })))
            if(status === 200) {
                save(result)
            }
        } catch (e){}
        setLoading(false)
    }

    const createElement = () => {
        let newObj = { id: null, name: '', color: '#669295', order: list.length + 1 }
        setList([...list, newObj])
    }

    useEffect(() => {
        if(isOpen) {
            getData().then()
        }
    },[isOpen])

    return (
        <div>
            <Modal isOpen={isOpen} title={'Статус'} loading={loading} save={putData} close={close}>
                <DDlist
                    createElement={createElement}
                    list={list}
                    setList={list => setList(list)}
                    save={putData}
                    isColor={!lookupItemConfig(type).config.hideColor}
                    isOrder={!lookupItemConfig(type).config.hideOrder}
                    field={lookupItemConfig(type).field}
                    secondField={lookupItemConfig(type).config.secondField}
                />
            </Modal>
        </div>
    )
}