export const TabsRender = ({ arr, setState, state }) => {
    return (
        <div className="tabs-header">
            <ul>
                {
                    arr.map((item) => (
                        <li key={item.value}><a className={(state.layout === item.value) ? "active" : null} onClick={() => setState({ layout: item.value })}>{item.label}</a></li>
                    ))
                }
            </ul>
        </div>
    )
}