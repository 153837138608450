import CustomCheckbox from "../../UI/CustomCheckbox";
import {ExtraColumnsRender} from "./ExtraColumnsRender";
import React from "react";
import {useHistory} from "react-router-dom";

export const TableBody = ({
    list,
    entity,
    checkElement,
    contextMenu,
    setContextMenu,
    massDoConfig,
    activeCheckItems,
    checkItems,
    columns,
    filterFields,
    additionalFields,
    RenderColumn,
    setModalType,
    setModalData,
    extra_fields_table_name,
    isDoubleClick,
    doubleClickLink
}) => {
    const history = useHistory()

    return (
        <tbody>
        {
            list && list[0] && list.map(item => (
                <tr
                    onDoubleClick={() => isDoubleClick && history.push(doubleClickLink ? `${doubleClickLink}/${item.id}` : `/${entity}/${item.id}`)}
                    onClick={(e) => { (e.ctrlKey) && checkElement(item.id) }}
                    //className={`pull-up ${contextMenu && contextMenu.id === item.id && 'active-tr'}`}
                    className={`new-tr ${contextMenu && contextMenu.id === item.id && 'active-tr'}`}
                    onContextMenu={(e) => {
                    e.preventDefault()
                    setContextMenu({ x: e.pageX, y: e.pageY, id: item.id })
                }}>
                    {!!massDoConfig && activeCheckItems && <td>
                        <CustomCheckbox
                            checked={checkItems.includes(item.id)}
                            onChange={() => checkElement(item.id)}
                        />
                    </td>}
                    <RenderColumn {...item} setModalType={setModalType} setModalData={setModalData} />
                    <ExtraColumnsRender
                        columns={columns}
                        filterFields={[ ...filterFields, ...additionalFields ]}
                        item={item}
                        extra_fields_table_name={extra_fields_table_name}
                    />
                    {/*<td onContextMenu={e => e.stopPropagation() }>
                        <div className="toogle-bottom fl-r">
                            <a className="is"><i className="flaticon-more" /></a>
                        </div>
                    </td>*/}
                </tr>
            ))
        }
        </tbody>
    )
}