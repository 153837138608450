import React, {useEffect, useState} from "react";
import {validationData} from "../components/UI/formValid";
import Modal from "../components/Modal";
import Input from "../components/UI/Input";
import {NewAutoSelect} from "../components/NewUi/NewAutoSelect";
import {myaxios} from "../services/axios";
import {parseBackValidationErrors} from "../helpers/parseBackValidationErrors";

const validationRules = {
    role_id: { required: true },
    first_name: { required: true, maxLength: 50 },
    last_name: { required: false, maxLength: 50 },
    email: { required: true, maxLength: 80, email: true },
    phone: { required: false, maxLength: 40 },
    password: { required: true, maxLength: 40, minLength: 6 },
    password_repeat: { required: true, maxLength: 40, minLength: 6 },
}

export const CreateUser = ({ close, save }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        role_id: null,
        role: null,
        shop_id: null,
        shop: null,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        password_repeat: '',
        tch: true
    })

    const closeModal = () => { setIsOpen(false); setTimeout(() => { close() }, 200) }

    useEffect(() => {
        setIsOpen(true)
    }, [])

    const saveData = async () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})

        if (values.password !== values.password_repeat) {
            setErrors({
                password: 'Паролі не співпадають',
                password_repeat: 'Паролі не співпадають'
            })
            return
        }

        setLoading(true)
        try {
            const { status, data, request } = await myaxios.post(`ajax/user`, {
                ...values,
                password: null,
                new_password: values.password
            })
            if (status === 201) {
                save()
                closeModal()
            }
            if (request?.status === 422) {
                const res = JSON.parse(request.response)
                if (res.errors) setErrors(parseBackValidationErrors(res.errors))
            }
        } catch (e) {

        }
        setLoading(false)
    }


    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Створити користувача'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <NewAutoSelect
                    label={'Роль'}
                    searchBy={'name'}
                    entity={values.role}
                    link={'ajax/role?filter[name][like]='}
                    updateData={(role) => setValues({ ...values, role_id: role && role.id, role, tch: false })}
                    onEnterData={(role) => setValues({ ...values, role_id: role && role.id, role, tch: false })}
                    errorMessage={errors.role_id} valid={!errors.role_id} shouldValidate
                    clear
                />
                <NewAutoSelect
                    label={'Магазин'}
                    searchBy={'name'}
                    entity={values.shop}
                    link={'ajax/shop?filter[name][like]='}
                    updateData={(shop) => setValues({ ...values, shop_id: shop && shop.id, shop, tch: false })}
                    onEnterData={(shop) => setValues({ ...values, shop_id: shop && shop.id, shop, tch: false })}
                    errorMessage={errors.shop_id} valid={!errors.shop_id} shouldValidate
                    clear
                />
                <Input
                    label={'Ім\'я'}
                    value={values.first_name}
                    onChange={e => setValues({...values, first_name: e.target.value, tch: false })}
                    errorMessage={errors.first_name} valid={!errors.first_name} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
                <Input
                    label={'Прізвище'}
                    value={values.last_name}
                    onChange={e => setValues({...values, last_name: e.target.value, tch: false })}
                    errorMessage={errors.last_name} valid={!errors.last_name} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
                <Input
                    label={'Email'}
                    value={values.email}
                    onChange={e => setValues({...values, email: e.target.value, tch: false })}
                    errorMessage={errors.email} valid={!errors.email} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
                <Input
                    label={'Телефон'}
                    value={values.phone}
                    onChange={e => setValues({...values, phone: e.target.value, tch: false })}
                    errorMessage={errors.phone} valid={!errors.phone} shouldValidate
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
                <Input
                    label={'Пароль'}
                    value={values.password}
                    onChange={e => setValues({...values, password: e.target.value, tch: false })}
                    errorMessage={errors.password} valid={!errors.password} shouldValidate
                    type={'password'}
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
                <Input
                    label={'Повторіть пароль'}
                    value={values.password_repeat}
                    onChange={e => setValues({...values, password_repeat: e.target.value, tch: false })}
                    errorMessage={errors.password_repeat} valid={!errors.password_repeat} shouldValidate
                    type={'password'}
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                />
            </div>
        </Modal>
    )
}